import React from 'react';
import './page.css';
import './../index.css'
import OrderHistory from "./OrderHistory";
import {isMobile, orderType} from "../util/Common";

class UserOrderHistory extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            userName: this.props.match.params.userName,
        }
    }

    // componentDidMount() {
    //     this.setState({
    //         userName: this.props.match.params.userName,
    //     })
    // }

    render() {
        let isMobileL = isMobile();
        return (
            <OrderHistory status={orderType.all} byUser={true} userName={this.state.userName} isMobile={isMobileL}/>
        )
    }
}

export default UserOrderHistory;