import {message} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const loginInputError = "输出错误！账号或密码长度需大于等于6位！"

const connectionError = "CT_ERROR";

const fail = (failInfo) => {
    message.error({content: failInfo, duration: 2, style: {marginTop: "10vh"}}).then(() => {
    })
}

const failMobile = (failInfo) => {
    message.error({
        content: failInfo,
        duration: 2,
        style: {marginTop: "10vh", fontSize: "26px"},
        icon: <CloseOutlined style={{color: "white", background: "red", fontSize: "26px", borderRadius: "50%"}}/>
    }).then(() => {
    })
}

const failReturn = (failInfo, url) => {
    message.error({content: failInfo, duration: 2, style: {marginTop: "10vh"}}).then(() => {
        window.location.href = url
    })
}

const failMobileReturn = (failInfo, url) => {
    message.error({
        content: failInfo,
        duration: 2,
        style: {marginTop: "10vh", fontSize: "26px"},
        icon: <CloseOutlined style={{color: "white", background: "red", fontSize: "26px", borderRadius: "50%"}}/>
    }).then(() => {
        window.location.href = url
    })
}

const success = (successInfo) => {
    message.success({content: successInfo, duration: 2, style: {marginTop: "10vh"}}).then(() => {
    })
}

const successReturn = (successInfo, url) => {
    message.success({content: successInfo, duration: 2, style: {marginTop: "10vh"}}).then(() => {
        window.location.href = url
    })
}

const successMobileReturn = (successInfo, url) => {
    message.success({
        content: successInfo,
        duration: 2,
        style: {marginTop: "10vh", fontSize: "26px"},
        icon: <CheckOutlined style={{color: "white", background: "green", fontSize: "26px", borderRadius: "50%"}}/>
    }).then(() => {
        window.location.href = url
    })
}

const findMsgByError = (error) => {
    console.log(error)
    switch (error) {
        case connectionError: {
            return "网络连接错误，请稍后重试！";
        }
        case "SYSTEM_ERROR": {
            return "网络连接错误，请稍后重试！";
        }
        case "INPUT_ERROR": {
            return "请求参数无效，请输入正确参数！";
        }
        case "USER_REPEAT": {
            return "用户名重复，请进行故障管理！";
        }
        case "NOT_MATCH_ERROR": {
            return "无法找到匹配信息，请重新输入参数！";
        }
        case "WRONG_PASS_ERROR": {
            return "密码错误，请输入正确密码！";
        }
        case "TIME_OUT": {
            return "该操作已超时，请在有效时间内完成对应操作！";
        }
        case "LOGIN_FIRST": {
            return "请先登录您的管理员账号后再执行该操作！";
        }
        case "VALID_ERROR": {
            return "当前认证已失效，请重新认证后执行该操作！";
        }
        case "OTHER_PLACE": {
            return "账号已在别处登录，请重新登录！";
        }
        default: {
            return "系统错误，请稍后重试！";
        }
    }
}

export {
    loginInputError,
    fail,
    connectionError,
    findMsgByError,
    successReturn,
    failReturn,
    success,
    failMobile,
    successMobileReturn,
    failMobileReturn
}