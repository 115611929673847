import React from 'react';
import './../index.css'
import {Input} from "antd";
import {SecurityScanOutlined} from "@ant-design/icons";
import {handleCountDown} from "../util/Tool";
import {fail} from "../util/ErrorMgr";

class ValidWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            second: this.props.resend,
            secCode: "",
        }
    }

    handleSendButton = (resend) => {
        this.props.parent.sendPhoneValid().then(result => {
            if (result) {
                handleCountDown(resend, this);
            } else {
                fail("验证码发送失败，请稍后重试！")
            }
        })
    }

    setCode(e) {
        this.setState({
            secCode: e.target.value,
        })
    }

    genPcValidWin = (time, resend, canSend, vKey) => {
        return (
            <div className={"valid-win"} key={vKey} style={{display: this.props.display ? "" : "none"}}>
                <div className={"valid-win-title"}>
                    <p style={{margin: 0}}>手机认证</p>
                </div>
                <div className={"valid-win-input"}>
                    <div style={{width: "100%"}}>
                        <p style={{fontWeight: "bold", marginLeft: "18%"}}>{"管理员手机号：" + this.props.phone}</p>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "10%"}}/>
                            <Input size="middle" placeholder="请输入手机验证码"
                                   prefix={<SecurityScanOutlined style={{color: "grey"}}/>}
                                   style={{fontSize: "14px", width: "40%"}}
                                   maxLength={8}
                                   onChange={(e) => this.setCode(e)}
                            />
                            <button className={"send-button"} style={{background: !canSend ? "grey" : "#3774bd"}}
                                    onClick={() => {
                                        this.handleSendButton(resend)
                                    }} disabled={!canSend}>{!canSend ? "( " + time + " ) 秒" : "获取验证码"}</button>
                            <div style={{width: "10%"}}/>
                        </div>
                    </div>
                </div>
                <div className={"valid-win-button"}>
                    <div className={"valid-win-button-inside"}>
                        <button className={"win-bottom-button"} style={{background: "rgb(194,102,93)"}} onClick={() => {
                            this.props.parent.cancelValid()
                        }}>取消
                        </button>
                        <button className={"win-bottom-button"} style={{background: "rgb(37,134,39)"}}
                                onClick={() => (this.props.parent.loginPost(this.state.secCode, false))}>确认
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    genMbValidWind = (time, resend, canSend, vKey) => {
        return (
            <div className={"mobile-valid-wim"} key={vKey} style={{display: this.props.display ? "" : "none"}}>
                <div className={"mobile-valid-win-title"}>
                    <p style={{margin: 0}}>管理员登录-手机认证</p>
                </div>
                <div className={"mobile-valid-win-input"}>
                    <div style={{width: "100%"}}>
                        <p style={{fontWeight: "bold", textAlign: "center", fontSize: "40px"}}>{"管理员手机号：" + this.props.phone}</p>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <input size="middle" placeholder=" 请 输 入 手 机 验 证 码"
                                   className={"mobile-phone-input"}
                                   maxLength={8}
                                   onChange={(e) => this.setCode(e)}
                            />
                            <button className={"mobile-send-button"} style={{background: !canSend ? "grey" : "#3774bd"}}
                                    onClick={() => {
                                        this.handleSendButton(resend)
                                    }} disabled={!canSend}>{!canSend ? "( " + time + " ) 秒" : "获取验证码"}</button>
                        </div>
                    </div>
                </div>
                <div className={"mobile-valid-win-button"}>
                    <div className={"mobile-valid-win-button-inside"}>
                        <button className={"mobile-win-bottom-button"} style={{background: "rgb(194,102,93)"}} onClick={() => {
                            this.props.parent.cancelValid()
                        }}>取消
                        </button>
                        <button className={"mobile-win-bottom-button"} style={{background: "rgb(37,134,39)"}}
                                onClick={() => (this.props.parent.loginPost(this.state.secCode, true))}>确认
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let time = this.state.second;
        let resend = this.props.resend
        let canSend = time === resend;
        let isMobile = this.props.isMobile;
        let vKey = this.props.vKey;
        return (
            isMobile ? this.genMbValidWind(time, resend, canSend, vKey) : this.genPcValidWin(time, resend, canSend, vKey)
        )
    }
}

export default ValidWin;