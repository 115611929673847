import React from 'react';
import './index.css'
import {Input} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone, KeyOutlined, UserOutlined} from "@ant-design/icons";
import {
    connectionError,
    fail,
    failMobile,
    findMsgByError,
    loginInputError,
    successMobileReturn,
    successReturn
} from "./util/ErrorMgr";
import {
    checkSecCode,
    data,
    error,
    loginPer, loginPost,
    phoneCodeType,
    phoneValid,
    sendGetRequest,
    servicePhoneTemp
} from "./util/Request";
import ValidWin from "./item/ValidWin";
import {addSwitch} from "./util/Tool";
import {isMobile} from "./util/Common";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validWin: false,
            responseData: "",
            phone: "",
            country: "",
        }
    }

    loginInfo = {
        loginPojo: {
            userName: '',
            pwd: '',
        }
    }

    cancelValid = () => {
        this.setState({
            validWin: false
        })
    }

    genLoginInfo(e, props) {
        let loginPojo = this.loginInfo.loginPojo;
        loginPojo[props] = e.target.value;
        this.setState({
            loginPojo
        })
    }

    sendPhoneValid = () => {
        return sendGetRequest(phoneValid, {
            phone: this.state.phone,
            code: this.state.country,
            tempId: servicePhoneTemp
        }).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
                return false;
            } else {
                return result;
            }
        })
    }

    loginPost = (code, isMobile) => {
        sendGetRequest(checkSecCode, {
            secCode: code,
            type: phoneCodeType,
        }).then(result => {
            if (result === connectionError) {
                let error = findMsgByError(connectionError)
                isMobile ? failMobile(error) : fail(error);
            } else {
                if (result) {
                    sendGetRequest(loginPost, {
                        userName: this.state.responseData["userName"],
                        secCode: code
                    }).then(result => {
                        if (result === connectionError) {
                            let errorL = findMsgByError(connectionError);
                            isMobile ? failMobile(errorL) : fail(errorL);
                        } else {
                            if (result.success) {
                                let responseData = this.state.responseData;
                                let auditInfo = {
                                    pwd: this.loginInfo.loginPojo.pwd,
                                    userName: responseData["userName"],
                                    userEmail: responseData["userEmail"],
                                    userPhone: responseData["userPhone"],
                                    country: responseData["country"],
                                    secCode: code,
                                }
                                sessionStorage.setItem("auditInfo", JSON.stringify(auditInfo));
                                addSwitch()
                                let seInfo = "登录成功！2秒后跳转操作页面！";
                                isMobile ? successMobileReturn(seInfo, "/mbMenu") : successReturn(seInfo, "/orders")
                            } else {
                                let errorF = findMsgByError(result[error]);
                                isMobile ? failMobile(errorF) : fail(errorF)
                            }
                        }
                    })
                } else {
                    let errorC = "验证码错误！请重新输入！";
                    isMobile ? failMobile(errorC) : fail(errorC)
                }
            }
        })
    }

    loginPre = (isMobile) => {
        let loginPojo = this.loginInfo.loginPojo;
        let userName = loginPojo.userName;
        let pwd = loginPojo.pwd;
        if (!userName || !pwd || userName.length < 6 || pwd.length < 6) {
            isMobile ? failMobile(loginInputError) : fail(loginInputError);
            return;
        }
        sendGetRequest(loginPer, loginPojo).then(result => {
            if (result === connectionError) {
                let error = findMsgByError(connectionError);
                isMobile ? failMobile(error) : fail(error);
                return;
            }
            if (result.success) {
                let responseData = result[data];
                this.setLoginPhoneValid(responseData);
            } else {
                let errorL = findMsgByError(result[error]);
                isMobile ? failMobile(errorL) : fail(errorL)
            }
        })
    }

    setLoginPhoneValid = (responseData) => {
        let country = responseData["country"];
        let phone = responseData["userPhone"];
        this.setState({
            validWin: true,
            responseData: responseData,
            country: country,
            phone: phone,
        })
    }

    convertPhone = (phone, country) => {
        if (phone && country) {
            // let v = "(" + country + ") ";
            let v = "";
            let nums = phone.split("");
            nums.forEach((item, index) => {
                if (index < 3 || index > nums.length - 4) {
                    v += item
                } else {
                    v += "*"
                }
            })
            return v;
        } else {
            return "none";
        }
    }

    onKeyDownChange(e) {
        if (e.keyCode === 13) {
            this.loginPre()
        }
    }

    render() {
        let valid = this.state.validWin;
        let phone = this.state.phone;
        let country = this.state.country;
        let isMobileL = isMobile();
        return (
            isMobileL ? [
                <div key={"m-valid-win"}>
                    <ValidWin display={valid} parent={this} resend={10} vKey={"m-valid"}
                              phone={this.convertPhone(phone, country)} isMobile={true}/>
                </div>,
                <div className={"mobile-login-page"} key={"m-login"} style={{display: valid ? "none" : ""}}>
                    <div className={"mobile-login-win"}>
                        <div className={"mobile-login-title"}>
                            <p style={{margin: 0}}>管理员登录</p>
                        </div>
                        <div className={"mobile-login-content"}>
                            <div className={"login-input-content"}>
                                <div style={{height: "100px"}}/>
                                <div className={"login-input-content-layout"}>
                                    <div className={"login-windows-info-input-line"} style={{flex: 1}}>
                                        <p style={{fontWeight: "bold"}}>管理员账号：</p>
                                        <input
                                            className={"mobile-login-input-line"}
                                            type={"text"}
                                            placeholder=" 请输入账号"
                                            maxLength={20}
                                            onChange={(e) => this.genLoginInfo(e, "userName")}
                                        />
                                    </div>
                                    <div className={"login-windows-info-input-line"} style={{flex: 1}}>
                                        <p style={{fontWeight: "bold"}}>管理员密码：</p>
                                        <input
                                            type={"password"}
                                            placeholder=" 请输入密码"
                                            className={"mobile-login-input-line"}
                                            maxLength={30}
                                            onChange={(e) => this.genLoginInfo(e, "pwd")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"mobile-login-button-content"}>
                                <button className={"mobile-login-button"} onClick={() => {
                                    this.loginPre(true)
                                }}>
                                    点击登录
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ] : [
                <div key={"pc-valid-win"}>
                    <ValidWin display={valid} parent={this} resend={10} vKey={"p-valid"}
                              phone={this.convertPhone(phone, country)} isMobile={false}/>
                </div>,
                <div className={"page-size"} style={{display: valid ? "none" : ""}} key={"login-in"}>
                    <div className={"login-win"}>
                        <div className={"login-title"}>
                            <p style={{margin: 0}}>管理员登录</p>
                        </div>
                        <div className={"login-content"}>
                            <div className={"login-input-content"}>
                                <div style={{height: "10%"}}/>
                                <div className={"login-input-content-layout"}>
                                    <div className={"login-windows-info-input-line"} style={{flex: 1}}>
                                        <p style={{fontWeight: "bold"}}>管理员账号：</p>
                                        <Input size="middle" placeholder="请输入账号"
                                               prefix={<UserOutlined style={{color: "grey"}}/>}
                                               style={{fontSize: "14px"}}
                                               maxLength={20}
                                               onChange={(e) => this.genLoginInfo(e, "userName")}
                                               onKeyDown={e => this.onKeyDownChange(e)}
                                        />
                                    </div>
                                    <div className={"login-windows-info-input-line"} style={{flex: 1}}>
                                        <p style={{fontWeight: "bold"}}>管理员密码：</p>
                                        <Input.Password
                                            size={"middle"}
                                            placeholder="请输入密码"
                                            prefix={<KeyOutlined style={{color: "grey"}}/>}
                                            style={{fontSize: "14px"}}
                                            iconRender={visible => visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>}
                                            maxLength={30}
                                            onChange={(e) => this.genLoginInfo(e, "pwd")}
                                            onKeyDown={e => this.onKeyDownChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"login-button-content"}>
                                <button className={"login-button"} onClick={() => {
                                    this.loginPre(false)
                                }}>
                                    点击登录
                                </button>
                            </div>
                        </div>
                    </div>
                </div>]
        )
    }
}

export default Login;