import React from 'react';
import './page.css';
import './../index.css';
import PageLayout from "./PageLayout";
import MenuL from "../item/MenuL";
import {isMobile, OrderPojo, rejectReasons, validNum} from "../util/Common";
import {awaitOrders, checkHost, data, error, processOrder, sendGetRequest, sendPostRequest} from "../util/Request";
import {
    connectionError,
    fail,
    failMobile,
    failMobileReturn,
    failReturn,
    findMsgByError,
} from "../util/ErrorMgr";
import {CheckOutlined, CloseOutlined, FileOutlined} from "@ant-design/icons";
import {message, Select, Spin, Input, Collapse} from "antd";
import {addSwitch} from "../util/Tool";
import CoreValidWin from "../item/CoreValidWin";
import MobileLayout from "./MobileLayout";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

class OrderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            awaitOrders: [],
            isLoaded: false,
            showAudit: false,
            curOrderNo: "",
            curPAmount: 0,
            curInviterName: "",
            curInviterAward: 0,
            curUserName: "",
            processing: false,
            rAward: 0,
            rAmount: 0,
            iPrAward: 0,
            iPrAmount: 0,
            showReject: false,
            rejectReason: "",
            checkCore: false,
            eKey: ""
        }
    }

    setAuditInfo = (auditInfo) => {
        this.setState({
            auditInfo: auditInfo
        })
    }

    componentDidMount() {
        this.findAwaitOrders();
    }

    findAwaitOrders = () => {
        sendGetRequest(awaitOrders, {await: "await"}).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (result.success) {
                    this.setState({
                        awaitOrders: result[data],
                        isLoaded: true
                    })
                } else {
                    fail(findMsgByError(result[error]))
                }
            }
        })
    }

    loadMenu = () => {
        return (
            <MenuL/>
        )
    }

    loadTableTitles = () => {
        return (
            <div className={"table-title-line"}>
                <span className={"common-line-item"}>{"用户名"}</span>
                <span className={"common-line-item"}>{"认购时间"}</span>
                <span className={"common-line-item"}>{"订单编号"}</span>
                <span className={"common-line-item"}>{"认购数量"}</span>
                <span className={"common-line-item"}>{"私募认购USDT"}</span>
                <span className={"common-line-item"}>{"邀请者"}</span>
                <span className={"common-line-item"}>{"奖励数量 (仅供参考)"}</span>
                <span className={"small-line-item"}>{"操作"}</span>
                <span style={{width: "6px"}}/>
            </div>
        )
    }

    loadTableData = (data, isMobileL) => {
        let dataList = [];
        data.forEach((item, index) => {
            let line = isMobileL ? this.loadingMBSingleData(item, index) : this.loadSingleData(item, index);
            dataList.push(line);
        })

        return dataList;
    }

    loadingEmptyData = (loaded) => {
        return (
            <div className={"empty-data"}>
                {loaded ? <div className={"empty-data-content"}>
                    <FileOutlined style={{marginRight: "8px"}}/>
                    {"暂无需要审核的订单"}
                </div> : <div className={"loading-data-notice"}>
                    {"订单加载中请稍后..."}
                </div>}
            </div>
        )
    }

    loadingMBSingleData = (item, index) => {
        let curOrderNo = item[OrderPojo.orderNo];
        let curPAmount = item[OrderPojo.pAmount];
        let curInviterName = item[OrderPojo.inviterName];
        let curInviterAward = item[OrderPojo.inviterAward];
        let curUserName = item[OrderPojo.userName];
        return (
            // this.genMBMainInfo(index, curUserName, item[OrderPojo.pTime], curPAmount)
            <CollapsePanel header={this.genMBMainInfo(index, curUserName, item[OrderPojo.pTime], curPAmount)}
                           key={index} showArrow={false} className={"mobile-collapse"}>
                <div className={"mobile-order-detail"}>
                    <div style={{
                        height: "70%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column"
                    }}>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"订单编号："}</span>
                            <span>{curOrderNo}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者："}</span>
                            <span>{curInviterName ? curInviterName : "N"}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者奖励 (仅供参考)："}</span>
                            <span>{curInviterAward}</span>
                        </div>
                    </div>
                    <div style={{height: "30%", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                        <button className={"mobile-audit-button"} style={{background: "rgb(0,182,20)"}}
                                onClick={() => {
                                    this.passOrder(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName,
                                        this.state.auditInfo["userName"], curInviterAward, curPAmount, "", 1, true)
                                }}>{"通过"}</button>
                        <button className={"mobile-audit-button"} style={{background: "rgb(208,11,11)"}}
                                onClick={() => {
                                    this.setState({
                                        showReject: true,
                                        curOrderNo: curOrderNo,
                                        curPAmount: curPAmount,
                                        curInviterName: curInviterName,
                                        curInviterAward: curInviterAward,
                                        curUserName: curUserName,
                                    })
                                }}>{"驳回"}</button>
                    </div>
                </div>
            </CollapsePanel>
        )
    }

    genMBMainInfo = (index, curUserName, pTime, curPAmount) => {
        return (
            <div className={"mobile-table-data-line"} key={index}>
                <span className={"mobile-data-line-op-button"}>{"操作"}</span>
                <div style={{width: "90%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"用户名："}</span>
                        <span>{curUserName}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"私募CZH数量："}</span>
                        <span>{curPAmount}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"认购时间："}</span>
                        <span>{pTime}</span>
                    </div>
                </div>
            </div>
        )
    }

    loadSingleData = (item, index) => {
        let curOrderNo = item[OrderPojo.orderNo];
        let curPAmount = item[OrderPojo.pAmount];
        let curInviterName = item[OrderPojo.inviterName];
        let curInviterAward = item[OrderPojo.inviterAward];
        let curUserName = item[OrderPojo.userName];
        return (
            <div className={"table-data-line"} key={index}>
                <span className={"common-line-item"}>{curUserName}</span>
                <span className={"common-line-item"}>{item[OrderPojo.pTime]}</span>
                <span className={"common-line-item"}>{curOrderNo}</span>
                <span className={"common-line-item"}>{curPAmount}</span>
                <span className={"common-line-item"}>{item[OrderPojo.usdtPrice]}</span>
                <span className={"common-line-item"}>{curInviterName}</span>
                <span className={"common-line-item"}>{curInviterAward}</span>
                <span className={"small-line-item"}>
                    <button className={"audit-button"} onClick={() => {
                        this.setState({
                            showAudit: true,
                            curOrderNo: curOrderNo,
                            curPAmount: curPAmount,
                            curInviterName: curInviterName,
                            curInviterAward: curInviterAward,
                            curUserName: curUserName,
                        })
                    }}>{"审核"}</button>
                </span>
            </div>
        )
    }

    loadPCAwaitTable = (data, loaded, hasData, isMobileL) => {
        return (
            <div className={"await-order-table"}>
                <div className={"order-table-titles"}>
                    {this.loadTableTitles()}
                </div>
                <div style={{height: "1%", background: hasData ? "#c0dcea" : "white"}}/>
                {hasData ? <div className={"await-order-table-data"}>
                    {this.loadTableData(data, isMobileL)}
                </div> : this.loadingEmptyData(loaded)}
            </div>
        )
    }

    loadMBAwaitTable = (data, loaded, hasData, isMobileL) => {
        return (
            <div>
                <div className={"mobile-order-table-titles"}>
                    <span>{"待审核订单"}</span>
                </div>
                <Collapse accordion>
                    {hasData ? this.loadTableData(data, isMobileL) : this.loadingMBEmptyData(loaded)}
                </Collapse>
                <div style={{height: "40px", background: "white"}}/>
            </div>
        )
    }

    loadingMBEmptyData = (loaded) => {
        return (
            <div className={"mobile-empty-data"}>
                {loaded ? <span>
                    {"暂无需要审核的订单"}
                </span> : <span>
                    {"订单加载中请稍后..."}
                </span>}
            </div>
        )
    }

    loadAwaitOrderTable = (data, loaded, isMobileL) => {
        let hasData = data.length !== 0;
        return (
            isMobileL ?
                this.loadMBAwaitTable(data, loaded, hasData, true) : this.loadPCAwaitTable(data, loaded, hasData, false)
        )
    }

    loadPageContent = (data, loaded, isMobileL) => {
        return (
            <div className={isMobileL ? "mobile-order-page-content" : "common-page-content"}>
                {this.loadAwaitOrderTable(data, loaded, isMobileL)}
            </div>
        )
    }

    passOrder = (curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName, auditName, rAward,
                 rAmount, rejectReason, orderStatus, isMobileL) => {
        if (orderStatus === 2) {
            if (!rejectReason) {
                let rEmpty = "请选择驳回原因！";
                isMobileL ? failMobile(rEmpty) : fail(rEmpty)
                return;
            }
        }
        this.setState({
            processing: true,
            showAudit: false,
            showReject: false,
        })
        let pojo = {
            url: processOrder, data: {
                auditName: auditName,
                userName: curUserName,
                orderNo: curOrderNo,
                cTime: "",
                // rAward: rAward,
                rAward: "0",
                rAmount: rAmount,
                orderStatus: orderStatus,
                rejectReason: rejectReason,
            }
        }
        sendPostRequest(pojo).then(result => {
            let loginUrl = "/"
            if (result === connectionError) {
                let conE = findMsgByError(connectionError);
                isMobileL ? failMobileReturn(conE, loginUrl) : failReturn(conE, loginUrl);
            } else {
                if (result.success) {
                    if (!isMobileL) {
                        addSwitch();
                    }
                    this.setState({
                        loaded: false,
                    })
                    if (isMobileL) {
                        message.success({
                            content: "该订单已完成审核！", duration: 2, style: {marginTop: "10vh", fontSize: "26px"},
                            icon: <CheckOutlined
                                style={{color: "white", background: "green", fontSize: "26px", borderRadius: "50%"}}/>
                        }).then(() => {
                            this.findAwaitOrders()
                        })
                    } else {
                        message.success({content: "该订单已完成审核！", duration: 2, style: {marginTop: "10vh"}}).then(() => {
                            this.findAwaitOrders()
                        })
                    }
                } else {
                    let errorL = result[error]
                    if (errorL === "LOGIN_FIRST") {
                        let eMsg = findMsgByError(errorL);
                        isMobileL ? failMobileReturn(eMsg, loginUrl) : failReturn(eMsg, loginUrl)
                    } else {
                        let rError = findMsgByError(result[error]);
                        isMobileL ? failMobile(rError) : fail(rError);
                    }
                }
                this.setState({
                    curOrderNo: "",
                    curPAmount: 0,
                    curInviterName: "",
                    curInviterAward: 0,
                    curUserName: "",
                    rejectReasons: "",
                    processing: false,
                    rAward: 0,
                    rAmount: 0,
                })
            }
        })
    }

    loadOrderWinTitle = (content, isMobile) => {
        return (
            <div className={isMobile ? "mobile-audit-order-win-title" : "audit-order-win-title"}>
                <div
                    className={isMobile ? "mobile-audit-order-win-title-content" : "audit-order-win-title-content"}>{content}</div>
                <div style={{width: "8%"}}>
                    <CloseOutlined style={{float: "right", marginRight: isMobile ? "40px" : "10px", cursor: "pointer"}}
                                   onClick={() => {
                                       this.setState({
                                           showAudit: false,
                                           showReject: false,
                                           curOrderNo: "",
                                           curPAmount: 0,
                                           curInviterName: "",
                                           curInviterAward: 0,
                                           rejectReason: "",
                                           rAward: 0,
                                           rAmount: 0,
                                           checkCore: false,
                                       })
                                   }}/>
                </div>
            </div>
        )
    }

    findRejectType(value) {
        this.setState({
            rejectReason: value,
        })
    }

    loadingRejectReasonInput = (orderNo) => {
        let misMatch = rejectReasons.PAYMENT_MISMATCH;
        let unpaid = rejectReasons.UNPAID;
        let unreasoned = rejectReasons.UNREASONED;
        return (
            <div className={"order-win-line"} style={{height: "40%"}} key={orderNo}>
                <Input.Group size="middle">
                    <Select style={{width: "100%"}}
                            placeholder="请选择驳回理由"
                            allowClear={true}
                            onChange={(value) => this.findRejectType(value)}
                    >
                        <Select.Option value={misMatch.key}>{misMatch.content}</Select.Option>
                        <Select.Option value={unpaid.key}>{unpaid.content}</Select.Option>
                        <Select.Option value={unreasoned.key}>{unreasoned.content}</Select.Option>
                    </Select>
                </Input.Group>
            </div>
        )
    }

    genAuditWin = (curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName, auditName, isReject) => {
        let rejectReason = this.state.rejectReason;
        let rAmount = this.state.rAmount;
        let rAward = this.state.rAward;
        return (
            <div className={"audit-order-win"}>
                {this.loadOrderWinTitle((isReject ? "驳回订单：" : "订单编号：") + curOrderNo, false)}
                <div className={"audit-order-win-body"}>
                    <div style={{
                        height: "74%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-around",
                    }}>
                        <div style={{height: "10%"}}/>
                        <div className={"order-win-line"}>
                            <div style={{height: "20%"}}>
                                <span style={{fontWeight: "bold", color: "rgb(88,88,88)"}}>{"购买人："}</span>
                                <span>{curUserName}</span>
                            </div>
                            <div style={{height: "20%"}}>
                                <span style={{fontWeight: "bold", color: "rgb(88,88,88)"}}>{"购买数："}</span>
                                <span>{isReject ? rAmount : curPAmount}</span>
                            </div>
                        </div>
                        <div className={"order-win-line"}>
                            <div style={{height: "20%"}}>
                                <span style={{fontWeight: "bold", color: "rgb(88,88,88)"}}>{"奖励人："}</span>
                                <span>{curInviterName ? curInviterName : "无邀请者"}</span>
                            </div>
                            <div style={{height: "20%", display: isReject ? "none" : ""}}>
                                <span style={{fontWeight: "bold", color: "rgb(88,88,88)"}}>{"奖励数 (仅供参考)："}</span>
                                <span>{curInviterName ? (isReject ? rAward : curInviterAward) : "0"}</span>
                            </div>
                        </div>
                        {isReject ? this.loadingRejectReasonInput(curOrderNo) : null}

                        <div style={{height: "10%"}}/>
                    </div>
                    <div style={{height: "26%", width: "90%", borderRadius: "0 0 5px 5px"}}>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            borderRadius: "0 0 5px 5px"
                        }}>
                            <button className={"order-pass"} onClick={() => {
                                if (isReject) {
                                    this.setState({
                                        checkCore: true,
                                    })
                                } else {
                                    this.passOrder(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName,
                                        auditName, curInviterAward, curPAmount, "", 1, false)
                                }
                            }}>
                                {isReject ? "编辑信息" : "通过"}
                            </button>
                            <button className={"order-reject"} onClick={() => {
                                if (isReject) {
                                    this.passOrder(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName,
                                        auditName, rAward, rAmount, rejectReason, 2, false)
                                } else {
                                    this.setState({showReject: true, showAudit: false})
                                }
                            }}>{isReject ? "确认驳回" : "驳回"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    closeCoreValid = () => {
        this.setState({
            checkCore: false,
        })
    }

    async handleEdit(rAmount, rAward, corePwd, isMobile) {
        return await sendGetRequest(checkHost, {pwd: corePwd, host: 1}).then(result => {
            if (result === connectionError) {
                let cError = findMsgByError(connectionError);
                isMobile ? failMobile(cError) : fail(cError);
                return false;
            } else {
                if (!result.success) {
                    let fError = findMsgByError(result[error]);
                    isMobile ? failMobile(fError) : fail(fError);
                    return false;
                } else {
                    this.setState({
                        rAmount: rAmount,
                        rAward: rAward,
                        checkCore: false,
                    })
                    return true;
                }
            }
        })
    }

    mbReasonPart = () => {
        let misMatch = rejectReasons.PAYMENT_MISMATCH;
        let unpaid = rejectReasons.UNPAID;
        let unreasoned = rejectReasons.UNREASONED;
        let rejectReason = this.state.rejectReason;
        return (
            <div className={"mobile-reject-reason"}>
                <button className={"mobile-reject-reason-op"}
                        style={rejectReason === misMatch.key ? {color: "white", background: "#2e78d0"} : {}}
                        onClick={() => {
                            this.setMBReason(misMatch.key)
                        }}>{misMatch.content}</button>
                <button className={"mobile-reject-reason-op"}
                        style={rejectReason === unpaid.key ? {color: "white", background: "#2e78d0"} : {}}
                        onClick={() => {
                            this.setMBReason(unpaid.key)
                        }}>{unpaid.content}</button>
                <button className={"mobile-reject-reason-op"}
                        style={rejectReason === unreasoned.key ? {color: "white", background: "#2e78d0"} : {}}
                        onClick={() => {
                            this.setMBReason(unreasoned.key)
                        }}>{unreasoned.content}</button>
            </div>
        )
    }

    setMbInputs = (e, prop) => {
        if (prop === "rAmount") {
            this.setState({
                iPrAmount: e.target.value,
            })
        } else if (prop === "rAward") {
            this.setState({
                iPrAward: e.target.value,
            })
        } else {
            this.setState({
                eKey: e.target.value,
            })
        }
    }

    mbEditOrderWin = () => {
        return (
            <div className={"mobile-reject-reason"}>
                <input placeholder=" 请输入实际私募数量"
                       type={"text"}
                       className={"mobile-reject-reason-edit-input"}
                       maxLength={12}
                       onChange={(e) => this.setMbInputs(e, "rAmount")}
                />
                {/*<input placeholder=" 请输入实际奖励数量"*/}
                {/*       type={"text"}*/}
                {/*       className={"mobile-reject-reason-edit-input"}*/}
                {/*       maxLength={12}*/}
                {/*       onChange={(e) => this.setMbInputs(e, "rAward")}*/}
                {/*/>*/}
                <input placeholder=" 请输入修改秘钥"
                       type={"text"}
                       className={"mobile-reject-reason-edit-input"}
                       maxLength={40}
                       onChange={(e) => this.setMbInputs(e, "eKey")}
                />
            </div>
        )
    }

    checkMobileEdit = () => {
        let rAmount = this.state.iPrAmount;
        // let rAward = this.state.iPrAward;
        let rAward = "0";
        if (rAmount === 0
            // || rAward === 0
            || !validNum.test(rAmount) || !validNum.test(rAward)) {
            failMobile("请输入有效数量！");
            return;
        }
        let key = this.state.eKey;
        if (!key) {
            failMobile("请输入有效秘钥！");
            return;
        }
        this.handleEdit(rAmount, rAward, key, true).then(res => {
            if (res) {
                this.setState({
                    iPrAmount: 0,
                    iPrAward: 0,
                    eKey: "",
                })
            }
        })
    }

    loadMobileReject = (showReject, curOrderNo, curUserName, curInviterName, curPAmount, curInviterAward) => {
        let rAmount = this.state.rAmount;
        let rAward = this.state.rAward;
        let checkCore = this.state.checkCore;
        return (
            <div className={"mobile-order-reject"} style={{display: showReject ? "" : "none"}} key={"m-reject"}>
                {this.loadOrderWinTitle("驳回订单：" + curOrderNo, true)}
                <div className={"mobile-order-reject-content"}>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"购买人："}</span>
                        <span>{curUserName}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"私募CZH数量："}</span>
                        <span>{rAmount}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"邀请人："}</span>
                        <span>{curInviterName ? curInviterName : "无邀请人"}</span>
                    </div>
                    {/*<div>*/}
                    {/*    <span style={{fontWeight: "bold"}}>{"奖励数量 (仅供参考)："}</span>*/}
                    {/*    <span>{curInviterName ? rAward : 0}</span>*/}
                    {/*</div>*/}
                    {checkCore ? this.mbEditOrderWin() : this.mbReasonPart()}
                    {checkCore ? <div className={"mobile-reject-reason-bottom"}>
                            <button className={"mobile-reject-reason-bottom-button"}
                                    style={{background: "rgb(102,102,102)"}} onClick={() => {
                                this.setState({checkCore: false, eKey: "", iPrAmount: 0, iPrAward: 0})
                            }}>{"取消编辑"}</button>
                            <button className={"mobile-reject-reason-bottom-button"}
                                    style={{background: "rgb(12,96,222)"}} onClick={() => {
                                this.checkMobileEdit()
                            }}>{"点击校验"}</button>
                        </div>
                        : <div className={"mobile-reject-reason-bottom"}>
                            <button className={"mobile-reject-reason-bottom-button"}
                                    style={{background: "rgb(0,182,20)"}} onClick={() => {
                                this.setState({checkCore: true})
                            }}>{"编辑信息"}</button>
                            <button className={"mobile-reject-reason-bottom-button"}
                                    style={{background: "rgb(208,11,11)"}} onClick={() => {
                                this.passOrder(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName,
                                    this.state.auditInfo["userName"], curInviterName ? rAward : 0, rAmount,
                                    this.state.rejectReason, 2, true);
                            }}>{"确认驳回"}</button>
                        </div>}
                </div>
            </div>
        )
    }

    setMBReason = (reason) => {
        this.setState({
            rejectReason: reason,
        })
    }

    render() {
        let data = this.state.awaitOrders;
        let loaded = this.state.isLoaded;
        let curOrderNo = this.state.curOrderNo;
        let curPAmount = this.state.curPAmount;
        let curInviterName = this.state.curInviterName;
        let curInviterAward = this.state.curInviterAward;
        let curUserName = this.state.curUserName;
        let auditName = this.state.auditInfo["userName"];
        let checkCore = this.state.checkCore;
        let showReject = this.state.showReject;
        let isMobileL = isMobile();
        return (
            [
                isMobileL ? null :
                    <div className={"item-msg-win"} style={{display: this.state.showAudit ? "" : "none"}}
                         key={"accept"}>
                        {this.genAuditWin(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName, auditName, false)}
                    </div>,
                isMobileL ? this.loadMobileReject(showReject, curOrderNo, curUserName, curInviterName, curPAmount, curInviterAward) :
                    <div className={"item-msg-win"} style={{display: this.state.showReject ? "" : "none"}}
                         key={"reject"}>
                        {this.genAuditWin(curOrderNo, curPAmount, curInviterName, curInviterAward, curUserName, auditName, true)}
                    </div>,
                isMobileL ? null : <div key={"core"}>
                    <CoreValidWin display={checkCore} key={curOrderNo} page={"order"} parent={this}/>
                </div>,
                isMobileL ? null :
                    <div className={"item-msg-win"} style={{display: this.state.processing ? "" : "none"}}
                         key={"process"}>
                        <div className={"processing-win"}>
                            <div style={{width: "100%"}}>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    <Spin/>
                                </div>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    {"订单提交中，请稍等..."}
                                </div>
                            </div>
                        </div>
                    </div>,
                <div key={"content"}>
                    {isMobileL ?
                        <MobileLayout contentL={this.loadPageContent(data, loaded, isMobileL)} parent={this}
                                      showData={!showReject}/> :
                        <PageLayout menuL={this.loadMenu()} contentL={this.loadPageContent(data, loaded, isMobileL)}
                                    parent={this}/>}
                </div>
            ]
        )
    }
}

export default OrderPage;