import React from 'react';
import './../index.css'
import {CloseOutlined} from "@ant-design/icons";

class ErrorMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unReadError: [],
            errors: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loaded !== this.props.loaded) {
            let errorMsg = this.props.errorMsg;
            this.loadErrors(errorMsg);
        }
    }

    loadErrors = (errorMsg) => {
        let isMobile = this.props.isMobile;
        let errors = [];
        let unReadError = [];
        if (errorMsg.length === 0) {
            errors.push(this.loadSingleError(null, "0", true, false, isMobile));
        } else {
            errorMsg.forEach((error, index) => {
                let isRead = error["read"];
                if (!isRead) {
                    unReadError.push(error);
                }
                let errorLine = this.loadSingleError(error, index, isRead, true, isMobile);
                errors.push(errorLine);
            })
            if (unReadError.length !== 0) {
                this.props.parent.setHasError();
            }
        }
        this.setState({
            errors: errors,
            unReadError: unReadError,
        })
    }

    loadSingleError = (error, index, isRead, hasError, isMobile) => {
        return (
            <div className={"error-single-line"} key={index}>
                {hasError ? [<div style={{fontSize: isMobile ? "30px" : "12px", display: "flex", flexDirection: "column"}} key={"top"}>
                    <div style={{width: "100%"}}>
                        <span style={{fontWeight: "bold", color: "rgb(109,109,109)"}}>{"触发时间："}</span>
                        <span>{error["errorTime"]}</span>
                    </div>
                    <div style={{width: "100%"}}>
                        <span style={{fontWeight: "bold", color: "rgb(109,109,109)"}}>{"错误用户："}</span>
                        <span>{error["userName"]}</span>
                    </div>
                </div>,
                    <div style={{height: isMobile ? "20px" : "6px"}} key={"sp"}/>,
                    <div className={isMobile ? "mobile-error-msg-words-win" : "error-msg-words-win"}
                         style={{
                             background: isRead ? "rgb(216,255,213)" : "rgb(255,191,191)",
                             padding: "10px 10px 10px 14px"
                         }} key={"down"}>
                        {error["errorMsg"]}
                    </div>] : <div>{"暂无错误信息！"}</div>}

            </div>
        )
    }

    render() {
        let parent = this.props.parent;
        let unReadError = this.state.unReadError;
        let isMobile = this.props.isMobile;

        return (
            <div className={isMobile ? "mobile-item-msg-win" : "item-msg-win"}
                 style={{display: this.props.showError ? "" : "none"}}
            >
                <div className={isMobile ? "mobile-error-msg-content" : "error-msg-content"}>
                    <div style={isMobile ? {
                        height: "6%",
                        width: "100%",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    } : {
                        height: "6%",
                        width: "100%",
                        background: "white",
                        borderRadius: "5px 5px 0 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <span style={{
                            width: "50%",
                            marginLeft: "4%",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                            fontSize: isMobile ? "40px" : "14px",
                            color: "rgb(111,111,111)"
                        }}>{"ERROR MESSAGE"}</span>
                        <div style={{width: "50%"}}>
                            <CloseOutlined style={{float: "right", marginRight: "4%", fontSize: isMobile ? "40px" : "14px"}} onClick={() => {
                                parent.setError(unReadError)
                            }}/>
                        </div>
                    </div>
                    <div style={{
                        height: "84%",
                        width: "100%",
                        background: "rgb(193,223,255)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div className={"error-msg-content-layout"}>
                            {this.state.errors}
                        </div>
                    </div>
                    <div
                        style={{
                            height: "10%",
                            width: "100%",
                            background: "white",
                            borderRadius: "0 0 5px 5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <button style={{
                            width: isMobile ? "180px" : "18%",
                            height: isMobile ? "70px" : "34px",
                            letterSpacing: "1px",
                            fontSize: isMobile ? "34px" : "14px",
                            border: "0",
                            background: "rgb(20,125,217)",
                            color: "white",
                            boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }} onClick={() => {
                            parent.setError(unReadError)
                        }}>确定
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ErrorMsg;