//60s倒计时
const handleCountDown = (seconds, dom) => {
    let second = seconds;
    const countDown = () => {
        if (second > 0) {
            second--;
            dom.setState({
                second: second
            })
        }
        if (second === 0) {
            second = seconds;
            dom.setState({
                second: second
            })
            return;
        }
        setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
};



const addSwitch = () => {
    sessionStorage.setItem("switch", "1");
}

const removeSwitch = () => {
    sessionStorage.removeItem("switch");
}

const hasSwitch = () => {
    let sw = sessionStorage.getItem("switch");
    return !!sw;
}

export {handleCountDown, addSwitch, removeSwitch, hasSwitch}