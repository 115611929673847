import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Content} from "antd/lib/layout/layout";
import Login from "./Login";
import OrderPage from "./page/OrderPage";
import AllHistory from "./page/AllHistory";
import AcceptHistory from "./page/AcceptHistory";
import RejectHistory from "./page/RejectHistory";
import UserOrderHistory from "./page/UserOrderHistory";
import UserRecord from "./page/UserRecord";
import MobileMenu from "./page/MobileMenu";
import MediaMgr from "./page/MediaMgr";
import CoreSetting from "./page/CoreSetting";

const userOrder = {
    path: "/orderByUser/:userName",
    component: UserOrderHistory,
}

const mediaMgr = {
    path: "/mediaMgr/:lang",
    component: MediaMgr,
}

class PageRoutes extends React.Component {
    render() {
        return (
            <Router>
                <Content>
                    {/*跳转时不重复显示主页exact*/}
                    <Route exact={true} path="/" component={Login}/>
                    <Route exact={true} path="/orders" component={OrderPage}/>
                    <Route exact={true} path="/allHistory" component={AllHistory}/>
                    <Route exact={true} path="/acceptHistory" component={AcceptHistory}/>
                    <Route exact={true} path="/rejectHistory" component={RejectHistory}/>
                    <Route exact={true} path={userOrder.path} component={userOrder.component}/>
                    <Route exact={true} path={mediaMgr.path} component={mediaMgr.component}/>
                    <Route exact={true} path="/userRecord" component={UserRecord}/>
                    <Route exact={true} path="/mbMenu" component={MobileMenu}/>
                    <Route exact={true} path="/coreSetting" component={CoreSetting}/>
                </Content>
            </Router>
        );
    }
}

export default PageRoutes