import React from 'react';
import './page.css';
import './../index.css';
import {connectionError, fail, failMobileReturn, failReturn, findMsgByError} from "../util/ErrorMgr";
import {autoLogin, data, error, findRecordErrors, sendGetRequest, sendPostRequest, updateErrors} from "../util/Request";
import ErrorMsg from "../item/ErrorMsg";
import {LeftOutlined} from "@ant-design/icons";
import {doLogout} from "../util/Common";

class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            hasError: false,
            showError: false,
            errorMsg: [],
            errorLoaded: false,
        }
    }

    componentDidMount() {
        let auditInfoStr = sessionStorage.getItem("auditInfo");
        if (!auditInfoStr) {
            failMobileReturn(findMsgByError("LOGIN_FIRST"), "/")
        } else {
            let auditInfo = JSON.parse(auditInfoStr);
            console.log(11111222)
            sendGetRequest(autoLogin, {userName: auditInfo["userName"]}).then(result => {
                if (result === connectionError) {
                    failMobileReturn(findMsgByError(connectionError), "/");
                } else {
                    if (!result) {
                        failMobileReturn("登录失效！请重新登录！", "/");
                    } else {
                        this.setState({
                            auditInfo: auditInfo,
                        })
                        sendGetRequest(findRecordErrors, {audit: auditInfo["userName"]}).then(result => {
                            if (result === connectionError) {
                                failMobileReturn(findMsgByError(connectionError), "/");
                                return;
                            }
                            if (result.success) {
                                this.setState({
                                    errorMsg: result[data],
                                    errorLoaded: true,
                                })
                                this.props.parent.setLogin();
                                this.props.parent.setAuditInfo(auditInfo)
                                return;
                            }
                            failMobileReturn(findMsgByError(result[error]), "/");
                        })
                    }
                }
            })
        }
    }

    setError = (unreadError) => {
        this.setState({
            showError: false,
        })
        if (unreadError.length === 0) {
            return;
        }
        let pojo = {
            url: updateErrors,
            data: unreadError
        }
        sendPostRequest(pojo).then(result => {
                if (result === connectionError) {
                    failReturn(findMsgByError(connectionError), "/");
                } else {
                    if (!result.success) {
                        let errorL = result[error];
                        if (errorL === "LOGIN_FIRST") {
                            failReturn(findMsgByError(errorL), "/")
                        } else {
                            fail(findMsgByError(errorL))
                        }
                    } else {
                        window.location.reload()
                    }
                }
            }
        )
    }

    setHasError = () => {
        this.setState({
            hasError: true,
        })
    }

    render() {
        let showError = this.state.showError;
        let auditInfo = this.state.auditInfo;
        return (
            [<div key={"error"}>
                <ErrorMsg showError={showError} parent={this} errorMsg={this.state.errorMsg}
                          loaded={this.state.errorLoaded} isMobile={true}/>
            </div>,
                <div className={"mobile-header"} key={"mobile-top-layer"}>
                    <LeftOutlined style={{width: "10%", fontSize: "40px"}} onClick={() => {
                        window.location.href = "/mbMenu"
                    }}/>
                    <div style={{width: "90%", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <div>
                            <div style={{width: "80px"}}/>
                            {"您好，" + this.state.auditInfo["userName"]}
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "20px"
                        }}
                             onClick={() => {
                                 this.setState({
                                     showError: true,
                                 })
                             }}>
                            <div style={{
                                width: "20px",
                                height: "20px",
                                background: this.state.hasError ? "red" : "rgb(20,246,83)",
                                borderRadius: "50%",
                                marginRight: "18px",
                            }}/>
                            <span>{"异常信息"}</span>
                        </div>
                        <div onClick={() => {doLogout(auditInfo["userName"], auditInfo["pwd"], true)}}>{"退出"}</div>
                    </div>
                </div>]
        )
    }
}

export default MobileHeader;