import React from 'react';
import './page.css';
import './../index.css';
import OrderHistory from "./OrderHistory";
import {isMobile, orderType} from "../util/Common";

class AcceptHistory extends React.Component {
    render() {
        let isMobileL = isMobile();
        return(
            <OrderHistory status={orderType.accept} byUser={false} isMobile={isMobileL}/>
        )
    }
}

export default AcceptHistory;