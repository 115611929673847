import React from 'react';
import './page.css';
import './../index.css';
import MobileHeader from "./MobileHeader";

class MobileLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            isLogin: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let auditInfo = this.state.auditInfo
        if (auditInfo !== prevState.auditInfo) {
            this.props.parent.setAuditInfo(auditInfo)
        }
    }

    setLogin = () => {
        this.setState(
            {
                isLogin: true,
            }
        )
    }

    setAuditInfo = (auditInfo) => {
        this.setState({
            auditInfo: auditInfo
        })
    }

    render() {
        return (
            <div>
                <MobileHeader parent={this}/>
                <div style={{display: this.props.showData ? "" : "none"}}>
                    {this.props.contentL}
                </div>
            </div>
        )
    }
}

export default MobileLayout;