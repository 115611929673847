import React from 'react';
import PageRoutes from "./PageRoutes";
import {error, isLogValid, logout, sendGetRequest} from "./util/Request";
import {hasSwitch} from "./util/Tool";
import {isMobile} from "./util/Common";
import {connectionError, fail, failMobile, failMobileReturn, failReturn, findMsgByError} from "./util/ErrorMgr";

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isPc: true,
        }
    }

    checkLogPlace = (isPc) => {
        let auditInfoStr = sessionStorage.getItem("auditInfo");
        if (auditInfoStr) {
            let auditInfo = JSON.parse(auditInfoStr);
            sendGetRequest(isLogValid, {
                userName: auditInfo["userName"],
                secCode: auditInfo["secCode"],
            }).then(res => {
                if (res === connectionError) {
                    let errorL = findMsgByError(connectionError);
                    isPc ? fail(errorL) : failMobile(errorL);
                } else {
                    if (!res.success) {
                        let errorL = res[error];
                        sessionStorage.removeItem("auditInfo");
                        let msg = findMsgByError(errorL);
                        isPc ? failReturn(msg, "/") : failMobileReturn(msg, "/")
                    }
                }
            })
        }
    }

    componentDidMount() {
        let isPc = !isMobile();
        this.checkLogPlace(isPc)
        if (isPc) {
            window.addEventListener('beforeunload', this.logout);
        }
        this.setState({
            isPc: isPc,
        })
    }

    componentWillUnmount() {
        if (this.state.isPc) {
            window.removeEventListener('beforeunload', this.logout)
        }
    }

    logout = () => {
        if (hasSwitch()) {
            return;
        }
        let auditInfoStr = sessionStorage.getItem("auditInfo");
            if (auditInfoStr) {
                let auditInfo = JSON.parse(auditInfoStr);
                sendGetRequest(logout, {
                    pwd: auditInfo["pwd"],
                    userName: auditInfo["userName"],
                    isTemp: true,
                }).then()
            }
    }

    render() {
        return (
            <div>
                <PageRoutes/>
            </div>
        )
    }
}

export default App;