import axios from "axios";
import {connectionError} from "./ErrorMgr";

// const header = "http://localhost:8080"

const picHeader = "https://f892.cjz.vip";

const header = "/host";

const loginPer = header + "/loginPre";

const loginPost = header + "/loginPost";

const findCoreInfo = header + "/findCoreInfos";

const editCoreInfo = header + "/editCoreInfo";

const findAwardInfo = header + "/showAwardInfo";

const findHostInfoByType = header + "/findHostInfoByType";

const phoneValid = header + "/sendPhoneSec";

const emailValid = header + "/sendEmailSec";

const coreValid = header + "/checkSecImpCode"

const updateAward = header + "/updateNewAwardInfos";

const checkSecCode = header + "/checkSecCode";

const logout = header + "/logout";

const userLoadRule = header + "/findUserLoadRule";

const findBatchUsers = header + "/findBatchUsers";

const findUserByPage = header + "/findPageUsers";

const findUserAwards = header + "/findUserAwards";

const findUserByUserName = header + "/findUserByUserName";

const exportAllUser = header + "/downloadAllUserInfo";

const exportUserAward = header + "/downloadAwardUserInfo";

const exportUserByScope = header + "/downloadScopeUserInfo";

const forceAdjustUserPre = header + "/forceAdjustUserPre";

const forceAdjustUserPost = header + "/forceAdjustUserPost";

const setServicePwd = header + "/resetServiceParam";

const setHostPwd = header + "/resetHostParam";

const processOrder = header + "/auditOrderWithMerge";

const findOrderByUser = header + "/findOrdersByUser";

const orderLoadRule = header + "/findOrderLoadRule";

const findBatchOrders = header + "/findBatchOrders";

const findOrderByPage = header + "/findOrdersByGivenPage";

const checkHost = header + "/findHostInfoByType"

const autoLogin = header + "/autologin";

const awaitOrders = header + "/findAwaitOrders";

const isLogValid = header + "/isLogValid";

const findRecordErrors = header + "/checkoutErrors";

const updateErrors = header + "/updateErrorsHasRead";

const findMediaSources = header + "/findMediaSources";

const editMediaSources = header + "/editMediaSources";

const phoneCodeType = "phCode";

const servicePhoneTemp = "1317741";

// const servicePhoneTemp = "1316066";

const serviceEmailTemp = "cn";

const data = "responseData";

const error = "errorType";

async function sendPostRequest(pojo) {
    axios.defaults.withCredentials = true;
    return await axios.post(pojo.url, JSON.stringify(pojo.data)).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

async function sendGetRequest(url, params) {
    axios.defaults.withCredentials = true;
    return await axios.get(url, {
        params: params
    }).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

const genPojo = (url, data) => {
    return {
        url: url,
        data: data,
    }
}

export {
    sendPostRequest,
    sendGetRequest,
    genPojo,
    loginPer,
    loginPost,
    data,
    error,
    phoneValid,
    servicePhoneTemp,
    checkSecCode,
    phoneCodeType,
    logout,
    autoLogin,
    awaitOrders,
    findRecordErrors,
    updateErrors,
    processOrder,
    checkHost,
    orderLoadRule,
    findBatchOrders,
    findOrderByPage,
    findOrderByUser,
    findBatchUsers,
    userLoadRule,
    findUserByPage,
    findUserAwards,
    forceAdjustUserPost,
    forceAdjustUserPre,
    findUserByUserName,
    exportAllUser,
    exportUserAward,
    exportUserByScope,
    findCoreInfo,
    findAwardInfo,
    findHostInfoByType,
    emailValid,
    coreValid,
    updateAward,
    serviceEmailTemp,
    editCoreInfo,
    findMediaSources,
    editMediaSources,
    setServicePwd,
    setHostPwd,
    picHeader,
    isLogValid
}
