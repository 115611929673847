import React from 'react';
import './page.css';
import './../index.css';
import PageLayout from "./PageLayout";
import {
    data,
    error, exportAllUser, exportUserAward, exportUserByScope,
    findBatchUsers, findUserAwards,
    findUserByPage, findUserByUserName, forceAdjustUserPost, forceAdjustUserPre,
    sendGetRequest,
    userLoadRule
} from "../util/Request";
import {connectionError, fail, failMobile, findMsgByError} from "../util/ErrorMgr";
import {
    batchProcessWin,
    handleBatchInit, isMobile, loadDataToMap,
    loadingPageDataTable, loadingPartitionLayout, noDataWin,
    switchPage, UserPojo, validNum
} from "../util/Common";
import MenuL from "../item/MenuL";
import {Collapse, Dropdown, Input, Menu, Popover, Spin} from "antd";
import {CloseOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import CoreValidWin from "../item/CoreValidWin";
import MenuItem from "antd/lib/menu/MenuItem";
import MobileLayout from "./MobileLayout";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const pageLimits = 12;

const singleBatch = 2400;

const {SubMenu} = Menu;

class UserRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            data: [],
            curPage: 1,
            breakPoints: [],
            breakpointsMap: new Map(),
            totalPages: 0,
            pageDataMap: new Map(),
            selectPage: 0,
            batchProcess: true,
            userName: "",
            userPojo: "",
            showAccepts: false,
            acceptsData: [],
            checkCore: false,
            selectUser: "",
            selectUId: "",
            processing: false,
            processingType: 0,
            showUserSearch: false,
            fromTime: 0,
            toTime: 0,
        }
    }

    componentDidMount() {
        sendGetRequest(userLoadRule, {pageLimit: pageLimits}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    let dataL = res[data];
                    let breakpoints = [];
                    if (dataL["breakPoints"]) {
                        breakpoints = dataL["breakPoints"];
                    }
                    sendGetRequest(findBatchUsers, {pageLimit: pageLimits, batchNo: 1}).then(userResult => {
                        if (userResult === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (userResult.success) {
                                let dataLoad = userResult[data];
                                handleBatchInit(this, breakpoints, dataL["totalPage"], dataLoad, pageLimits);
                            } else {
                                fail(findMsgByError(userResult[error]))
                            }
                        }
                    })
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    exportMenu = () => (
        <Menu key={"export-menu"}>
            <MenuItem className={"sub-menu-item"} key={"all-data"} onClick={() => {
                this.exportData(exportAllUser, "userInfo.zip")
            }}>
                <div>全部导出</div>
            </MenuItem>
            <MenuItem className={"sub-menu-item"} key={"award-data"} onClick={() => {
                this.exportData(exportUserAward, "userAwardInfo.zip")
            }}>
                <div>奖励关系导出</div>
            </MenuItem>
            <SubMenu title="按日期导出" key={"date-data"}>
                <MenuItem key={"export-date-line"} disabled={true}>
                    <div className={"export-date-outline"}>
                        <div key={"from"}>{"From"}</div>
                        <div key={"from-input"}>
                            <Input size="small" placeholder="起始时间"
                                   style={{fontSize: "14px", width: "80px"}}
                                   maxLength={8}
                                   onChange={(e) => this.setTimeScope(e, "from")}
                            />
                        </div>
                        <div key={"to"}>{"To"}</div>
                        <div key={"to-input"}>
                            <Input size="small" placeholder="结束时间"
                                   style={{fontSize: "14px", width: "80px"}}
                                   maxLength={8}
                                   onChange={(e) => this.setTimeScope(e, "to")}
                            />
                        </div>
                        <div key={"export-button"}>
                            <button className={"export-button"} onClick={() => {
                                this.exportDataByTime()
                            }}>{"导出"}</button>
                        </div>
                    </div>
                </MenuItem>
            </SubMenu>
        </Menu>
    )

    loadMenu = () => {
        return (
            <MenuL/>
        )
    }

    downloadUerInfo = (url, name) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.withCredentials = true;
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status === 200) {
                let blob = this.response;
                console.log(blob)
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = name;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        };
        xhr.send();
    }

    exportData = (url, name) => {
        let finUrl = url + "?audit=" + this.state.auditInfo["userName"] + "&time=" + Math.random();
        this.downloadUerInfo(finUrl, name)
    }

    exportDataByTime = () => {
        let from = this.state.fromTime;
        let to = this.state.toTime;
        if (!from || !to || from.length !== 8 || to.length !== 8 || !validNum.test(from) || !validNum.test(to)) {
            fail("请输入有效8位数日期，例:19991001");
            return;
        }
        if (parseInt(to) < parseInt(from)) {
            fail("起始时间不能晚于结束时间！")
            return;
        }
        let scope = from + "-" + to;
        let finUrl = exportUserByScope + "?audit=" + this.state.auditInfo["userName"] + "&scope=" + scope + "&time=" + Math.random();
        this.downloadUerInfo(finUrl, "UserInfo" + scope + ".zip");
    }

    loadTableTitles = () => {
        return (
            <div className={"table-title-line"}>
                <span className={"common-line-item"}>{"用户名"}</span>
                <span className={"common-line-item"}>{"联系方式"}</span>
                <span className={"common-line-item"}>{"私募CZH数量"}</span>
                <span className={"common-line-item"}>{"奖励CZH数量"}</span>
                <span className={"common-line-item"}>{"邀请人"}</span>
                <span className={"common-line-item"}>{"邀请人奖励数"}</span>
                <span className={"common-line-item"}>{"时间信息"}</span>
                <span className={"common-line-item"}>{"邀请列表"}</span>
                <span className={"small-line-item"}>{"操作"}</span>
                <span style={{width: "6px"}}/>
            </div>
        )
    }

    genPhone = (userPhone, country) => {
        if (userPhone) {
            return "(" + country + ") " + userPhone;
        }
        return "N";
    }

    genTimeInfo = (regTime, loginTime, purchaseTime) => {
        return (
            <div className={"show-info-win"}>
                <div>
                    <span style={{fontWeight: "bold"}}>{"注册时间："}</span>
                    {(regTime ? this.findDate(regTime) : "N")}
                </div>
                <div>
                    <span style={{fontWeight: "bold"}}>{"最后登录："}</span>
                    {(loginTime ? this.findDate(loginTime) : "N")}
                </div>
                <div>
                    <span style={{fontWeight: "bold"}}>{"最后认购："}</span>
                    {(purchaseTime ? purchaseTime : "N")}
                </div>
            </div>
        )
    }

    genUserBaseInfo = (phone, email) => {
        return (
            <div className={"show-info-win"}>
                <div>
                    <span style={{fontWeight: "bold"}}>{"手机号码："}</span>
                    {phone}
                </div>
                <div>
                    <span style={{fontWeight: "bold"}}>{"邮箱地址："}</span>
                    {(email ? email : "N")}
                </div>
            </div>
        )
    }

    loadSingleData = (item, index) => {
        let userEmail = item[UserPojo.userEmail];
        let inviterName = item[UserPojo.inviterName];
        let userName = item[UserPojo.userName];
        return (
            <div className={"table-data-line"} key={index}>
                <span className={"common-line-item"}>{userName}</span>
                <span className={"common-line-item"}>
                     <div>
                        <Popover
                            content={this.genUserBaseInfo(this.genPhone(item[UserPojo.userPhone], item[UserPojo.country]), userEmail)}>
                            <div className={"check-line-info"}>{"显示"}</div>
                        </Popover>
                    </div>
                </span>
                <span className={"common-line-item"}>{item[UserPojo.purchaseCZH]}</span>
                <span className={"common-line-item"}>{item[UserPojo.awardCZH]}</span>
                <span className={"common-line-item"}>{inviterName ? inviterName : "N"}</span>
                <span className={"common-line-item"}>{item[UserPojo.inviterAward]}</span>
                <span className={"common-line-item"}>
                     <div>
                        <Popover
                            content={this.genTimeInfo(item[UserPojo.regTime], item[UserPojo.lastLogin], item[UserPojo.lastPurchase])}>
                            <div className={"check-line-info"}>{"显示"}</div>
                        </Popover>
                    </div>
                </span>
                <span className={"common-line-item"}>
                    <button className={"audit-button"} onClick={() => {
                        this.findAccepts(userName)
                    }}>
                        {"查询"}
                    </button>
                </span>
                <span className={"small-line-item"}>
                    <button className={"audit-button"} onClick={() => {
                        this.setState({checkCore: true, selectUser: userName, selectUId: item[UserPojo.uId]})
                    }}>
                        {"调整"}
                    </button>
                </span>
            </div>
        )
    }

    findAccepts = (inviter) => {
        sendGetRequest(findUserAwards, {userName: inviter}).then(awards => {
            if (awards === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (awards.success) {
                    let accepts = awards[data];
                    let acceptsData = this.state.acceptsData;
                    console.log(awards)
                    if (accepts && accepts.length !== 0) {
                        acceptsData = accepts;
                    }
                    this.setState({
                        acceptsData: acceptsData,
                        showAccepts: true,
                    })
                } else {
                    fail(findMsgByError(awards[error]))
                }
            }
        })
    }

    loadDataExportWin = () => {
        return (
            <div className={"user-record-data-export"}>
                <Dropdown overlay={this.exportMenu()} placement="bottomCenter" arrow key={"user-data"}>
                    <div className={"user-record-data-export-button"}>
                        {"导出用户数据"}
                    </div>
                </Dropdown>
            </div>
        )
    }

    genMBMainInfo = (index, curUserName, regTime, purchaseCZH, awardCZH) => {
        return (
            <div className={"mobile-table-data-line"}
                 style={{height: "250px"}} key={index}>
                <span className={"mobile-data-line-op-button"}
                      style={{height: "64px", width: "110px", background: "rgb(36,130,226)"}}>{"详情"}</span>
                <div style={{width: "90%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"用户名："}</span>
                        <span>{curUserName}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"注册时间："}</span>
                        <span>{this.findDate(regTime)}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"私募CZH数量："}</span>
                        <span>{purchaseCZH}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"奖励CZH数量："}</span>
                        <span>{awardCZH}</span>
                    </div>
                </div>
            </div>
        )
    }

    handleUid = (uid) => {
        let len = uid.length;
        let uIds = uid.split("");
        if (len > 45) {
            let id = ""
            uIds.forEach((item, index) => {
                if (index < 11 || index > len - 11) {
                    id += item;
                } else {
                    if (index === 11) {
                        id += "*****"
                    }
                }
            })
            return id;
        }
        return uid;
    }

    loadMbSingleData = (item, index) => {
        let userEmail = item[UserPojo.userEmail];
        let inviterName = item[UserPojo.inviterName];
        let userName = item[UserPojo.userName];
        let indexL = index + Math.random();
        return (
            <CollapsePanel
                header={this.genMBMainInfo(indexL, userName, item[UserPojo.regTime], item[UserPojo.purchaseCZH], item[UserPojo.awardCZH])}
                key={indexL} showArrow={false} className={"mobile-collapse"} style={{minHeight: "300px"}}>
                <div className={"mobile-order-detail"} style={{height: "500px"}}>
                    <div style={{
                        height: "80%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column"
                    }}>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"UID："}</span>
                            <span>{this.handleUid(item[UserPojo.uId])}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"手机号码："}</span>
                            <span>{this.genPhone(item[UserPojo.userPhone], item[UserPojo.country])}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邮箱地址："}</span>
                            <span>{userEmail ? userEmail : "N"}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"最近登录时间："}</span>
                            <span>{this.findDate(item[UserPojo.lastLogin])}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"最近购买时间："}</span>
                            <span>{item[UserPojo.lastPurchase]}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者："}</span>
                            <span>{inviterName ? inviterName : "N"}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者奖励："}</span>
                            <span>{item[UserPojo.inviterAward]}</span>
                        </div>
                    </div>
                    <div style={{
                        height: "20%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column"
                    }}>
                        <button className={"mobile-user-accept-button"} onClick={() => {
                            console.log(userName)
                            this.setState({
                                acceptsData: [],
                                showAccepts: false,
                                showUserSearch: false,
                            }, () => {
                                this.findAccepts(userName)
                            })
                        }}>用户邀请列表
                        </button>
                    </div>
                </div>
            </CollapsePanel>
        )
    }

    loadMbAccept = (accepts) => {
        let lines = [];
        accepts.forEach((item, index) => {
            let line = this.loadMbSingleData(item, index + "a");
            lines.push(line);
        })
        if (lines.length === 0) {
            return noDataWin("暂无用户数据", true);
        }
        return lines;
    }

    loadMbPageContent = (curPage, curDataMap, totalPages) => {
        let batchProcess = this.state.batchProcess;
        let showUserSearch = this.state.showUserSearch;
        let showAccepts = this.state.showAccepts;
        return (
            <div className={"mobile-order-page-content"}>
                <div>
                    <div className={"mobile-order-table-titles"}>
                        <span>{"用户信息"}</span>
                    </div>
                    <div className={"mobile-order-table-titles"} style={{background: "rgb(151,209,255)"}}>
                        <span>{"用户搜索："}</span>
                        <input placeholder=" 请输入用户名"
                               maxLength={40}
                               style={{outline: "none"}}
                               onChange={(e) => this.setUserName(e)}
                        />
                        <button className={"mobile-user-search-bottom"} onClick={() => {
                            this.searchUser(true)
                        }}>{"搜索"}</button>
                    </div>
                    <div>
                        {batchProcess ? batchProcessWin(true) :
                            <Collapse
                                accordion>{showUserSearch ? this.loadMbSingleData(this.state.userPojo, "usr")
                                :
                                (showAccepts ? this.loadMbAccept(this.state.acceptsData)
                                    :
                                    loadingPageDataTable(curPage, curDataMap, this, true, "暂无用户数据"))}</Collapse>}
                    </div>
                    <div style={{height: "40px", background: "white"}}/>
                </div>
                {batchProcess ? null : (
                    (showUserSearch || showAccepts) ?
                        <div className={"mobile-order-table-partition"} onClick={() => {
                            window.location.href = "/userRecord"
                        }}>
                            <LeftOutlined style={{marginRight: "30px"}}/>
                            <span>
                                {"返回所有用户"}
                            </span>
                        </div>
                        :
                        <div className={"mobile-order-table-partition"}>
                            <LeftOutlined style={{marginRight: "30px"}} onClick={() => {
                                if (curPage !== 1) {
                                    switchPage(curPage - 1, null, false, curDataMap, this)
                                }
                            }}/>
                            <span>
                                {"当前页数：" + curPage + "/" + totalPages}
                            </span>
                            <RightOutlined style={{marginLeft: "30px"}} onClick={() => {
                                if (curPage !== totalPages) {
                                    switchPage(curPage + 1, null, false, curDataMap, this)
                                }
                            }}/>
                        </div>
                )}
            </div>
        )
    }

    loadPageContent = (curPage, curDataMap, totalPages) => {
        let batchProcess = this.state.batchProcess;
        return (
            <div className={"common-page-content"}>
                <div className={"order-history-layout"}>
                    <div className={"order-history-search"}>
                        {this.loadDataExportWin()}
                        {this.loadSearchWin()}
                    </div>
                    <div className={"order-history-table"}>
                        <div className={"order-table-titles"}>
                            {this.loadTableTitles()}
                        </div>
                        <div className={"history-table-content-layout"}>
                            <div style={{width: "100%", height: "10px"}}/>
                            {batchProcess ? batchProcessWin(false)
                                :
                                loadingPageDataTable(curPage, curDataMap, this, false, "暂无用户数据")}
                        </div>
                    </div>
                    <div className={"page-partition-layout"}>
                        {loadingPartitionLayout(curPage, totalPages, null, curDataMap, this)}
                    </div>
                </div>
            </div>
        )
    }

    toSelectPage = (selectPage) => {
        sendGetRequest(findUserByPage, {
            passNum: (selectPage - 1) * pageLimits,
            pageLimit: pageLimits,
        }).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    this.setState({
                        curPage: selectPage,
                    })
                    loadDataToMap(res[data], selectPage, pageLimits, this);
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    handleToSelectPage = (totalPages, status, curDataMap) => {
        let selectPage = this.state.selectPage;
        if (validNum.test(selectPage)) {
            selectPage = parseInt(selectPage);
            if (selectPage < 1 || selectPage > totalPages) {
                fail("请输入范围内页码！")
            } else {
                if (curDataMap.has(selectPage)) {
                    switchPage(selectPage, status, true, curDataMap, this)
                    return;
                }
                this.toSelectPage(selectPage);
            }
        } else {
            fail("请输入有效整数页码！");
        }
    }

    loadBatch = (targetPage, status, curDataMap) => {
        let breakPoints = this.state.breakpoints;
        if (curDataMap.has(targetPage) || breakPoints.length === 0) {
            return false;
        }
        let breakPointsMap = this.state.breakPointsMap;
        let point = 0;
        let currentStart = (targetPage - 1) * pageLimits + 1;

        for (let i in breakPoints) {
            if (breakPoints.hasOwnProperty(i)) {
                let should = singleBatch * (parseInt(i) + 2);
                if (should >= currentStart) {
                    point = breakPoints[i];
                    break;
                }
            }
        }
        let isBreakLoad = breakPointsMap.get(point);
        if (isBreakLoad) {
            return false;
        }
        this.setState({
            batchProcess: true,
        })
        let batchNum = (breakPoints.indexOf(point) + 2);
        sendGetRequest(findBatchUsers, {
            pageLimit: pageLimits,
            batchNo: batchNum,
        }).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    breakPointsMap.set(point, true);
                    loadDataToMap(res[data], (singleBatch * (batchNum - 1) / pageLimits + 1), pageLimits, this)
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    setSelectPage = (e) => {
        this.setState({
            selectPage: e.target.value,
        })
    }

    searchUser = (isMobile) => {
        let userName = this.state.userName;
        if (!userName) {
            let error = "请输入有效的用户名";
            isMobile ? failMobile(error) : fail(error);
        } else {
            this.setState({
                processing: true,
                processingType: 2,
            })
            sendGetRequest(findUserByUserName, {userName: userName}).then(res => {
                this.setState({
                    processing: false,
                    processingType: 0,
                })
                if (res === connectionError) {
                    let cError = findMsgByError(connectionError)
                    isMobile ? failMobile(cError) : fail(findMsgByError(connectionError));
                } else {
                    if (res.success) {
                        this.setState({
                            showUserSearch: true,
                            userPojo: res[data],
                        })
                    } else {
                        let errorL = res[error];
                        let info = errorL === "NOT_MATCH_ERROR" ? "该用户不存在！" : findMsgByError(errorL);
                        isMobile ? failMobile(info) : fail(info)
                    }
                }
            })
        }
    }

    setTimeScope = (e, prop) => {
        if ("from" === prop) {
            this.setState({
                fromTime: e.target.value,
            })
        } else {
            this.setState({
                toTime: e.target.value,
            })
        }
    }

    setUserName = (e) => {
        this.setState({
            userName: e.target.value,
        })
    }

    onKeyDownChange(e) {
        if (e.keyCode === 13) {
            this.searchUser(false)
        }
    }

    setAuditInfo = (auditInfo) => {
        this.setState({
            auditInfo: auditInfo
        })
    }

    findDate = (date) => {
        if (!date) {
            return "N";
        }
        let times = date.toString().split("");
        if (times.length !== 8) {
            return date;
        }
        let result = "";
        for (let i in times) {
            if (times.hasOwnProperty(i)) {
                let index = parseInt(i);
                if (index === 4 || index === 6) {
                    result += "-"
                }
                result += times[i];
            }
        }
        return result;
    }

    loadSearchWin = () => {
        return (
            <div className={"search-bar"}>
                <div style={{width: "34%", fontWeight: "bold", color: "rgb(255,255,255)"}}>
                    {"用户搜索："}
                </div>
                <Input size="small" placeholder="请输入用户名"
                       style={{fontSize: "12px", width: "36%"}}
                       maxLength={40}
                       onChange={(e) => this.setUserName(e)}
                       onKeyDown={e => this.onKeyDownChange(e)}
                />
                <button className={"user-search-bottom"} onClick={() => {
                    this.searchUser(false)
                }}>{"搜索"}</button>
            </div>
        )
    }

    loadInvitersWin = () => {
        let data = this.state.acceptsData;
        console.log(data)
        if (data.length !== 0) {
            return this.loadAwardsLines(data);
        } else {
            return this.noAwardWin();
        }
    }

    noAwardWin = () => {
        return (
            <div>{"该用户暂无邀请人员！"}</div>
        )
    }

    loadAwardsLines = (data) => {
        let lines = [];
        data.forEach((item, index) => {
            let line = (
                <div className={"accept-line-data"} key={index}>
                    <div className={"accept-line-data-item"}>
                        <div>
                            <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"用户名："}</span>
                            {item[UserPojo.userName]}
                        </div>
                    </div>
                    <div className={"accept-line-data-item"}>
                        <div>
                            <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"私募CZH数量："}</span>
                            {item[UserPojo.purchaseCZH]}
                        </div>
                        <div>
                            <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"邀请者奖励："}</span>
                            {item[UserPojo.inviterAward]}
                        </div>
                    </div>
                </div>
            )
            lines.push(line);
        })
        return lines
    }

    loadInfoWinTitle = (title, isUsr) => {
        return (
            <div className={"accepts-win-title"}>
                <div className={"audit-order-win-title-content"}>{title}</div>
                <div style={{width: "8%"}}>
                    <CloseOutlined style={{float: "right", marginRight: "10px", cursor: "pointer"}} onClick={() => {
                        if (isUsr) {
                            this.setState({
                                showUserSearch: false,
                                userPojo: "",
                            })
                        } else {
                            this.setState({
                                showAccepts: false,
                                acceptsData: [],
                            })
                        }
                    }}/>
                </div>
            </div>
        )
    }

    closeCoreValid = () => {
        this.setState({
            checkCore: false,
            selectUser: "",
            selectUId: "",
        })
    }

    setProcessing = (pValue) => {
        this.setState({
            processing: pValue,
            processingType: 0
        })
    }

    async handleEdit(purchaseCZH, corePwd) {
        let selectUser = this.state.selectUser
        this.setState({
            processing: true,
            processingType: 1,
            checkCore: false,
        })
        console.log(this.state.auditInfo)
        return await sendGetRequest(forceAdjustUserPre, {
            pwd: corePwd,
            auditName: this.state.auditInfo["userName"],
            userName: selectUser,
            czh: purchaseCZH,
        }).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
                return false;
            } else {
                if (!result.success) {
                    fail(findMsgByError(result[error]));
                    return false;
                } else {
                    return sendGetRequest(forceAdjustUserPost, {
                        userName: selectUser,
                        uId: this.state.selectUId,
                    }).then(userRes => {
                        if (userRes === connectionError) {
                            fail(findMsgByError(connectionError));
                            return false;
                        } else {
                            if (userRes.success) {
                                return true;
                            } else {
                                fail(findMsgByError(userRes[error]))
                                return false;
                            }
                        }
                    })
                }
            }
        })
    }

    loadSearchResult = () => {
        let pojo = this.state.userPojo;
        if (pojo) {
            let inviterName = pojo[UserPojo.inviterName];
            let phone = pojo[UserPojo.userPhone];
            let email = pojo[UserPojo.userEmail];
            let lastP = pojo[UserPojo.lastPurchase];
            let userName = pojo[UserPojo.userName];
            let uId = pojo[UserPojo.uId];
            return (<div className={"userPojo-layout"}>
                <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"邮箱地址:"}</span>
                <span>{email ? email : "N"}</span>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"手机号码: "}</span>
                    <span>{phone ? ("(" + pojo[UserPojo.country] + ") " + phone) : "N"}</span>
                </div>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"私募CZH数量: "}</span>
                    <span>{pojo[UserPojo.purchaseCZH]}</span>
                </div>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"奖励CZH数量: "}</span>
                    <span>{pojo[UserPojo.awardCZH]}</span>
                </div>
                <div style={{height: "10px"}}/>
                <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"邀请人:"}</span>
                <span>{inviterName ? inviterName : "N"}</span>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"邀请人奖励数: "}</span>
                    <span>{pojo[UserPojo.inviterAward]}</span>
                </div>
                <div style={{height: "10px"}}/>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"注册时间: "}</span>
                    <span>{this.findDate(pojo[UserPojo.regTime])}</span>
                </div>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"最后登录: "}</span>
                    <span>{this.findDate(pojo[UserPojo.lastLogin])}</span>
                </div>
                <div>
                    <span style={{fontWeight: "bold", color: "rgb(87,87,87)"}}>{"最后认购: "}</span>
                    <span>{lastP ? lastP : "N"}</span>
                </div>
                <div>
                    <Popover
                        content={uId}>
                        <div className={"check-UID-info"}>{"显示用户UID"}</div>
                    </Popover>
                </div>
                <div className={"userPojo-bottom"}>
                    <button className={"userPojo-button"} onClick={() => {
                        this.findAccepts(userName)
                    }}>{"邀请列表"}</button>
                    <button className={"userPojo-button"} onClick={() => {
                        this.setState({checkCore: true, selectUser: userName, selectUId: uId})
                    }}>{"调整用户"}</button>
                </div>
            </div>)
        }
        return <div/>
    }

    loadMbUserWin = (curPage, pageDataMap, totalPages) => {
        return (
            <MobileLayout contentL={this.loadMbPageContent(curPage, pageDataMap, totalPages)} parent={this}
                          showData={true}/>
        )
    }

    render() {
        let totalPages = this.state.totalPages;
        let pageDataMap = this.state.pageDataMap;
        let curPage = this.state.curPage;
        let showUserSearch = this.state.showUserSearch;
        let processingType = this.state.processingType;
        let isMobileL = isMobile();
        return (
            isMobileL ? this.loadMbUserWin(curPage, pageDataMap, totalPages)
                :
                [<div className={"item-msg-win"} style={{display: showUserSearch ? "" : "none"}} key={"search-win"}>
                    <div className={"user-award-list-win"}>
                        {this.loadInfoWinTitle(this.state.userName, true)}
                        <div className={"user-award-content"}>
                            <div style={{minHeight: "20px"}}/>
                            {this.loadSearchResult()}
                        </div>
                    </div>
                </div>,
                    <div className={"item-msg-win"} style={{display: this.state.processing ? "" : "none"}}
                         key={"process-win"}>
                        <div className={"processing-win"}>
                            <div style={{width: "100%"}}>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    <Spin/>
                                </div>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    {processingType === 1 ? "数据提交中，请稍等..." : "搜索中，请稍后..."}
                                </div>
                            </div>
                        </div>
                    </div>,
                    <div className={"item-msg-win"} style={{display: this.state.showAccepts ? "" : "none"}}
                         key={"award-win"}>
                        <div className={"user-award-list-win"}>
                            {this.loadInfoWinTitle("获得奖励明细", false)}
                            <div className={"user-award-content"}>
                                <div style={{minHeight: "20px"}}/>
                                {this.loadInvitersWin()}
                            </div>
                        </div>
                    </div>,
                    <div key={"core-win"}>
                        <CoreValidWin display={this.state.checkCore} page={"user"} parent={this}
                                      user={this.state.selectUser}/>
                    </div>,
                    <div key={"pc-page-layout"}>
                        <PageLayout menuL={this.loadMenu()}
                                    contentL={this.loadPageContent(curPage, pageDataMap, totalPages)}
                                    parent={this}/>
                    </div>
                ]
        )
    }
}

export default UserRecord;