import React from 'react';
import './page.css';
import './../index.css';
import PageLayout from "./PageLayout";
import MenuL from "../item/MenuL";
import MobileLayout from "./MobileLayout";
import {
    data,
    error,
    findBatchOrders,
    findOrderByPage,
    findOrderByUser,
    orderLoadRule,
    sendGetRequest
} from "../util/Request";
import {connectionError, fail, findMsgByError} from "../util/ErrorMgr";
import {
    batchProcessWin,
    handleBatchInit,
    loadDataToMap, loadingPageDataTable, loadingPartitionLayout,
    OrderPojo,
    rejectReasons, switchPage,
    validNum
} from "../util/Common";
import {Collapse, Dropdown, Input, Menu, Popover} from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import {CaretDownOutlined, FileOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const pageLimits = 12;

const singleBatch = 2400;

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            data: [],
            curPage: 1,
            breakPoints: [],
            breakpointsMap: new Map(),
            totalPages: 0,
            pageDataMap: new Map(),
            selectPage: 0,
            curOrderStatus: this.props.status,
            batchProcess: true,
            userName: "",
            userOrders: [],
            byUser: this.props.byUser,
        }
    }

    componentDidMount() {
        if (this.props.byUser) {
            sendGetRequest(findOrderByUser, {userName: this.props.userName}).then(res => {
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res.success) {
                        let dataL = res[data];
                        this.setState({
                            userOrders: dataL,
                            batchProcess: false,
                        })
                        loadDataToMap(dataL, 1, dataL.length, this)
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
            return;
        }
        let status = this.props.status;
        sendGetRequest(orderLoadRule, {pageLimit: pageLimits, status: status}).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (result.success) {
                    let dataL = result[data];
                    let breakpoints = [];
                    if (dataL["breakPoints"]) {
                        breakpoints = dataL["breakPoints"];
                    }
                    sendGetRequest(findBatchOrders, {
                        pageLimit: pageLimits,
                        status: status,
                        batchNum: 1
                    }).then(res => {
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (res.success) {
                                console.log("brk")
                                console.log(breakpoints)
                                let dataLoad = res[data];
                                handleBatchInit(this, breakpoints, dataL["totalPage"], dataLoad, pageLimits)
                            } else {
                                fail(findMsgByError(res[error]))
                            }
                        }
                    })
                } else {
                    fail(findMsgByError(result[error]))
                }
            }
        })
    }

    noDataWin = () => {
        return (
            <div className={"no-data-win"}>
                <div className={"empty-data-content"}>
                    <FileOutlined style={{marginRight: "8px"}}/>
                    {"暂无订单数据！"}
                </div>
            </div>
        )
    }

    genMBMainInfo = (index, curUserName, status, curPAmount, pTime, rejectReason) => {
        return (
            <div className={"mobile-table-data-line"}
                 style={{height: "250px", background: status === 1 ? "rgb(222,255,221)" : "rgb(255,224,224)"}} key={index}>
                <span className={"mobile-data-line-op-button"} style={{height: "64px", width: "110px", background: "rgb(172,172,172)"}}>{"详情"}</span>
                <div style={{width: "90%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"用户名："}</span>
                        <span>{curUserName}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"认购CZH数量："}</span>
                        <span>{curPAmount}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"认购时间："}</span>
                        <span>{pTime}</span>
                    </div>
                    <div>
                        <span style={{fontWeight: "bold"}}>{"订单状态："}</span>
                        <span>{status === 1 ? "通过" : "驳回：" + rejectReason}</span>
                    </div>
                </div>
            </div>
        )
    }

    loadMbSingleData = (item, index) => {
        let curOrderNo = item[OrderPojo.orderNo];
        let curPAmount = item[OrderPojo.pAmount];
        let curInviterName = item[OrderPojo.inviterName];
        let curInviterAward = item[OrderPojo.inviterAward];
        let curUserName = item[OrderPojo.userName];
        let status = item[OrderPojo.orderStatus];
        let rejectReason = status === 1 ? "" : rejectReasons[item[OrderPojo.rejectReason]].content;
        let indexL = index + Math.random();
        return (
            <CollapsePanel
                header={this.genMBMainInfo(indexL, curUserName, status, curPAmount, item[OrderPojo.pTime], rejectReason)}
                key={indexL} showArrow={false} className={"mobile-collapse"} style={{minHeight: "300px"}}>
                <div className={"mobile-order-detail"}>
                    <div style={{
                        height: "70%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column"
                    }}>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"订单编号："}</span>
                            <span>{curOrderNo}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"审核时间："}</span>
                            <span>{item[OrderPojo.cTime]}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"实际到账数量："}</span>
                            <span>{item[OrderPojo.rAmount]}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者："}</span>
                            <span>{curInviterName ? curInviterName : "N"}</span>
                        </div>
                        <div>
                            <span style={{fontWeight: "bold"}}>{"邀请者奖励 (仅供参考)："}</span>
                            <span>{curInviterAward}</span>
                        </div>
                        {/*<div>*/}
                        {/*    <span style={{fontWeight: "bold"}}>{"实际奖励数量 (仅供参考)："}</span>*/}
                        {/*    <span>{item[OrderPojo.rAward]}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </CollapsePanel>
        )
    }

    loadSingleData = (item, index) => {
        let curOrderNo = item[OrderPojo.orderNo];
        let curPAmount = item[OrderPojo.pAmount];
        let curInviterName = item[OrderPojo.inviterName];
        let curInviterAward = item[OrderPojo.inviterAward];
        let curUserName = item[OrderPojo.userName];
        let status = item[OrderPojo.orderStatus];
        return (
            <div className={"table-data-line"} key={index}>
                <span className={"common-line-item"}>{curUserName}</span>
                <span className={"common-line-item"}>{item[OrderPojo.pTime]}</span>
                <span className={"common-line-item"} style={{width: "16.5%"}}>{curOrderNo}</span>
                <span className={"common-line-item"}>{curPAmount}</span>
                <span className={"common-line-item"}>{item[OrderPojo.rAmount]}</span>
                <span className={"common-line-item"}>{curInviterName}</span>
                <span className={"common-line-item"} style={{width: "12%"}}>{curInviterAward}</span>
                {/*<span className={"common-line-item"} style={{width: "12%"}}>{item[OrderPojo.rAward]}</span>*/}
                <span className={"small-line-item"}
                      style={{color: status === 1 ? "green" : "red"}}>
                    {status === 1 ? "通过" : <div>
                        <Popover content={rejectReasons[item[OrderPojo.rejectReason]].content}>
                            <div style={{cursor: "pointer"}}>{"驳回"}</div>
                        </Popover>
                    </div>}
                </span>
            </div>
        )
    }

    loadTableTitles = (byUser) => {
        return (
            <div className={"table-title-line"}>
                <span className={"common-line-item"}>{"用户名"}</span>
                <span className={"common-line-item"}>{"认购时间"}</span>
                <span className={"common-line-item"} style={{width: "16.5%"}}>{"订单编号"}</span>
                <span className={"common-line-item"}>{"认购数量"}</span>
                <span className={"common-line-item"}>{"实际到账"}</span>
                <span className={"common-line-item"}>{"邀请者"}</span>
                <span className={"common-line-item"} style={{width: "12%"}}>{"奖励数量 (仅参考)"}</span>
                {/*<span className={"common-line-item"} style={{width: "12%"}}>{"实际奖励 (仅参考)"}</span>*/}
                {byUser ? <span className={"small-line-item"}>{"状态"}</span> :
                    <Dropdown overlay={this.statusSelectWin()} placement="bottomCenter" arrow>
                        <div className={"small-line-item-switch"}>
                            <span>{"状态"}</span>
                            <span><CaretDownOutlined/></span>
                        </div>
                    </Dropdown>}

                <span style={{width: "6px"}}/>
            </div>
        )
    }

    statusSelectWin = () => {
        return (
            <Menu>
                <MenuItem className={"sub-menu-item"} key={"all"} onClick={() => {
                    window.location.href = "/allHistory"
                }}>
                    <div>历史订单</div>
                </MenuItem>
                <MenuItem className={"sub-menu-item"} key={"pass"} onClick={() => {
                    window.location.href = "/acceptHistory"
                }}>
                    <div>通过订单</div>
                </MenuItem>
                <MenuItem className={"sub-menu-item"} key={"reject"} onClick={() => {
                    window.location.href = "/rejectHistory"
                }}>
                    <div>驳回订单</div>
                </MenuItem>
            </Menu>
        )
    }

    setSelectPage = (e) => {
        this.setState({
            selectPage: e.target.value,
        })
    }

    loadBatch = (targetPage, status, curDataMap) => {
        let breakPoints = this.state.breakpoints;
        if (curDataMap.has(targetPage) || breakPoints.length === 0) {
            return false;
        }
        let breakPointsMap = this.state.breakPointsMap;
        let point = 0;
        let currentStart = (targetPage - 1) * pageLimits + 1;

        console.log(breakPoints)
        for (let i in breakPoints) {
            if (breakPoints.hasOwnProperty(i)) {
                let should = singleBatch * (parseInt(i) + 2);
                console.log(should)
                console.log(currentStart)
                if (should >= currentStart) {
                    point = breakPoints[i];
                    break;
                }
            }
        }
        let isBreakLoad = breakPointsMap.get(point);
        if (isBreakLoad) {
            return false;
        }
        this.setState({
            batchProcess: true,
        })
        let batchNum = (breakPoints.indexOf(point) + 2);
        sendGetRequest(findBatchOrders, {
            pageLimit: pageLimits,
            status: status,
            batchNum: batchNum,
        }).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                console.log(point)
                console.log((singleBatch * (batchNum - 1) / pageLimits + 1))
                if (res.success) {
                    breakPointsMap.set(point, true);
                    loadDataToMap(res[data], (singleBatch * (batchNum - 1) / pageLimits + 1), pageLimits, this)
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    setUserName = (e) => {
        this.setState({
            userName: e.target.value,
        })
    }

    searchUser = () => {
        let userName = this.state.userName;
        if (!userName) {
            fail("请输入有效的用户名");
        } else {
            window.location.href = "/orderByUser/" + userName;
        }
    }

    onKeyDownChange(e) {
        if (e.keyCode === 13) {
            this.searchUser()
        }
    }

    loadSearchWin = () => {
        return (
            <div className={"search-bar"}>
                <div style={{width: "34%", fontWeight: "bold", color: "rgb(255,255,255)"}}>
                    {"用户订单搜索："}
                </div>
                <Input size="small" placeholder="请输入用户名"
                       style={{fontSize: "12px", width: "36%"}}
                       maxLength={40}
                       onChange={(e) => this.setUserName(e)}
                       onKeyDown={e => this.onKeyDownChange(e)}
                />
                <button className={"user-search-bottom"} onClick={() => {
                    this.searchUser()
                }}>{"搜索"}</button>
            </div>
        )
    }

    loadPcContent = (curPage, curDataMap, totalPages, status, byUser, batchProcess) => {
        return (
            <div className={"common-page-content"}>
                <div className={"order-history-layout"}>
                    <div className={"order-history-search"} style={{justifyContent: "right"}}>
                        {this.loadSearchWin()}
                    </div>
                    <div className={"order-history-table"}
                         style={byUser ? {height: "93%", borderRadius: "0 0 5px 5px"} : {}}>
                        <div className={"order-table-titles"}>
                            {this.loadTableTitles(byUser)}
                        </div>
                        <div className={"history-table-content-layout"}>
                            <div style={{width: "100%", height: "10px"}}/>
                            {batchProcess ? batchProcessWin(false) : loadingPageDataTable(curPage, curDataMap, this, false, "暂无订单数据！")}
                        </div>
                    </div>
                    {byUser ? null : <div className={"page-partition-layout"}>
                        {loadingPartitionLayout(curPage, totalPages, status, curDataMap, this)}
                    </div>}
                </div>
            </div>
        )
    }

    loadMobileContent = (curPage, curDataMap, totalPages, status, byUser, batchProcess) => {
        return (
            <div className={"mobile-order-page-content"}>
                <div>
                    <div className={"mobile-order-table-titles"}>
                        <span>{"历史订单"}</span>
                    </div>
                    <div className={"mobile-order-table-titles"} style={{background: "rgb(151,209,255)"}}>
                        <span>{"用户订单搜索："}</span>
                        <input placeholder=" 请输入用户名"
                               maxLength={40}
                               style={{outline: "none"}}
                               onChange={(e) => this.setUserName(e)}
                        />
                        <button className={"mobile-user-search-bottom"} onClick={() => {
                            this.searchUser()
                        }}>{"搜索"}</button>
                    </div>
                    <div className={"mobile-order-table-titles"} style={{justifyContent: "space-evenly", color: "rgb(55,110,175)"}}>
                        <span onClick={() => {
                            window.location.href = "/allHistory"
                        }}>{"全部订单"}</span>
                        <span>{"|"}</span>
                        <span onClick={() => {
                            window.location.href = "/acceptHistory"
                        }}>{"通过订单"}</span>
                        <span>{"|"}</span>
                        <span onClick={() => {
                            window.location.href = "/rejectHistory"
                        }}>{"驳回订单"}</span>
                    </div>
                    <div>
                        {batchProcess ? batchProcessWin(true) :
                            <Collapse accordion>{loadingPageDataTable(curPage, curDataMap, this, true, "暂无订单数据！")}</Collapse>}
                    </div>
                    <div style={{height: "40px", background: "white"}}/>
                </div>
                {batchProcess ? null : (byUser ?
                        <div className={"mobile-order-table-partition"} onClick={() => {
                            window.location.href = "/allHistory"
                        }}>
                            <LeftOutlined style={{marginRight: "30px"}}/>
                            <span>
                                {"返回全部历史订单"}
                            </span>
                        </div>
                        :
                        <div className={"mobile-order-table-partition"}>
                            <LeftOutlined style={{marginRight: "30px"}} onClick={() => {
                                if (curPage !== 1) {
                                    switchPage(curPage - 1, status, false, curDataMap, this)
                                }
                            }}/>
                            <span>
                                {"当前页数：" + curPage + "/" + totalPages}
                            </span>
                            <RightOutlined style={{marginLeft: "30px"}} onClick={() => {
                                if (curPage !== totalPages) {
                                    switchPage(curPage + 1, status, false, curDataMap, this)
                                }
                            }}/>
                        </div>
                )}
            </div>
        )
    }

    loadPageContent = (curPage, curDataMap, totalPages, status, byUser, isMobile) => {
        let batchProcess = this.state.batchProcess;
        return (
            isMobile ? this.loadMobileContent(curPage, curDataMap, totalPages, status, byUser, batchProcess)
                :
                this.loadPcContent(curPage, curDataMap, totalPages, status, byUser, batchProcess)
        )
    }

    handleToSelectPage = (totalPages, status, curDataMap) => {
        let selectPage = this.state.selectPage;
        if (validNum.test(selectPage)) {
            selectPage = parseInt(selectPage);
            if (selectPage < 1 || selectPage > totalPages) {
                fail("请输入范围内页码！")
            } else {
                if (curDataMap.has(selectPage)) {
                    switchPage(selectPage, status, true, curDataMap, this)
                    return;
                }
                sendGetRequest(findOrderByPage, {
                    passNum: (selectPage - 1) * pageLimits,
                    pageLimit: pageLimits,
                    status: status
                }).then(res => {
                    if (res === connectionError) {
                        fail(findMsgByError(connectionError));
                    } else {
                        if (res.success) {
                            this.setState({
                                curPage: selectPage,
                            })
                            loadDataToMap(res[data], selectPage, pageLimits, this);
                        } else {
                            fail(findMsgByError(res[error]))
                        }
                    }
                })
            }
        } else {
            fail("请输入有效整数页码！");
        }
    }

    loadMenu = () => {
        return (
            <MenuL/>
        )
    }

    setAuditInfo = (auditInfo) => {
        this.setState({
            auditInfo: auditInfo
        })
    }

    render() {
        let totalPages = this.state.totalPages;
        let pageDataMap = this.state.pageDataMap;
        let curPage = this.state.curPage;
        let status = this.state.curOrderStatus;
        let byUser = this.state.byUser;
        let isMobile = this.props.isMobile;
        return (
            isMobile ? <MobileLayout
                    contentL={this.loadPageContent(curPage, pageDataMap, totalPages, status, byUser, isMobile)}
                    parent={this}
                    showData={true}/>
                :
                <PageLayout menuL={this.loadMenu()}
                            contentL={this.loadPageContent(curPage, pageDataMap, totalPages, status, byUser, isMobile)}
                            parent={this}/>
        )
    }
}

export default OrderHistory;