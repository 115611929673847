import React from 'react';
import './page.css';
import {connectionError, fail, failReturn, findMsgByError} from "../util/ErrorMgr";
import {addSwitch, removeSwitch} from "../util/Tool";
import {
    autoLogin,
    data,
    error,
    findRecordErrors,
    sendGetRequest,
    sendPostRequest,
    updateErrors
} from "../util/Request";
import {PoweroffOutlined} from "@ant-design/icons";
import ErrorMsg from "../item/ErrorMsg";
import {doLogout} from "../util/Common";

class PageLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            isLogin: false,
            hasError: false,
            showError: false,
            errorMsg: [],
            errorLoaded: false,
        }
    }

    setError = (unreadError) => {
        this.setState({
            showError: false,
        })
        if (unreadError.length === 0) {
            return;
        }
        let pojo = {
            url: updateErrors,
            data: unreadError
        }
        sendPostRequest(pojo).then(result => {
                console.log(result)
                if (result === connectionError) {
                    failReturn(findMsgByError(connectionError), "/");
                } else {
                    if (!result.success) {
                        let errorL = result[error];
                        if (errorL === "LOGIN_FIRST") {
                            failReturn(findMsgByError(errorL), "/")
                        } else {
                            fail(findMsgByError(errorL))
                        }
                    } else {
                        addSwitch();
                        window.location.reload()
                    }
                }
            }
        )
    }

    setHasError = () => {
        this.setState({
            hasError: true,
        })
    }

    componentDidMount() {
        removeSwitch();
        let auditInfoStr = sessionStorage.getItem("auditInfo");
        if (!auditInfoStr) {
            failReturn(findMsgByError("LOGIN_FIRST"), "/")
        } else {
            let auditInfo = JSON.parse(auditInfoStr);
            sendGetRequest(autoLogin, {userName: auditInfo["userName"]}).then(result => {
                if (result === connectionError) {
                    failReturn(findMsgByError(connectionError), "/");
                } else {
                    if (!result) {
                        failReturn("登录失效！请重新登录！", "/");
                    } else {
                        this.setState({
                            auditInfo: auditInfo,
                            isLogin: true,
                        })
                        this.props.parent.setAuditInfo(auditInfo);
                        sendGetRequest(findRecordErrors, {audit: auditInfo["userName"]}).then(result => {
                            if (result === connectionError) {
                                failReturn(findMsgByError(connectionError), "/");
                                return;
                            }
                            if (result.success) {
                                this.setState({
                                    errorMsg: result[data],
                                    errorLoaded: true,
                                })
                                return;
                            }
                            failReturn(findMsgByError(result[error]), "/");
                        })
                    }
                }
            })
        }
    }

    loadingPageHeader = (auditName, pwd) => {
        return (
            <div className={"page-header"}>
                <div style={{
                    width: "12%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "white",
                    letterSpacing: "1px"
                }}>{"SHI 客服管理平台"}</div>
                <div style={{width: "62%"}}/>
                <div className={"header-func-outer"}>
                    <div style={{width: "48%", textAlign: "center"}}>{"您好，" + auditName}</div>
                    <div style={{
                        width: "52%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        fontWeight: "bold"
                    }}>
                        <div style={{display: "flex", alignItems: "center", width: "50%", cursor: "pointer"}}
                             onClick={() => {
                                 this.setState({showError: true})
                             }}>
                            <div style={{
                                width: "14px",
                                height: "14px",
                                background: this.state.hasError ? "red" : "rgb(20,246,83)",
                                borderRadius: "50%",
                                marginRight: "8px",
                            }}/>
                            <span>异常信息</span>
                        </div>
                        <div style={{width: "50%", textAlign: "center", cursor: "pointer"}} onClick={() => {
                            doLogout(auditName, pwd, false)
                        }}>
                            <PoweroffOutlined style={{marginRight: "6px"}}/>
                            {"退出"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadingFooter = () => {
        return (
            <span className={"page-footer"}>Copyright © 2021 XXX. All Rights Reserved. XXXXXXX公司 版权所有</span>
        )
    }

    render() {
        let auditInfo = this.state.auditInfo;
        let isLogin = this.state.isLogin;
        let showError = this.state.showError;
        console.log(2222)
        console.log(isLogin)
        return (
            isLogin ? [
                <div key={"error"}>
                    <ErrorMsg showError={showError} parent={this} errorMsg={this.state.errorMsg} loaded={this.state.errorLoaded} isMobile={false}/>
                </div>,
                <div className={"page-common-layout-header"} key={"header"}>
                    {this.loadingPageHeader(auditInfo["userName"], auditInfo["pwd"])}
                </div>,
                <div className={"page-common-layout"} key={"layout"}>
                    {this.props.menuL}
                    <div className={"page-content-outer"}>
                        {this.props.contentL}
                    </div>
                </div>,
                <div className={"page-common-layout-footer"} key={"footer"}>
                    {this.loadingFooter()}
                </div>,
            ] : [<div style={{height: "6vh", minHeight: "40px"}} key={"be"}/>,
                <div className={"page-common-layout"}
                     key={"layout"}
                     style={{display: "flex", alignItems: "center", justifyContent: "center", background: "#e0e0e0"}}>
                    {"页面加载中，请稍后..."}
                </div>,
                <div style={{height: "6vh", minHeight: "40px"}} key={"ae"}/>]
        )
    }
}

export default PageLayout;