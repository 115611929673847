import React from 'react';
import './page.css';
import './../index.css';
import MenuL from "../item/MenuL";
import PageLayout from "./PageLayout";
import {
    coreValid,
    data, editCoreInfo, emailValid,
    error,
    findAwardInfo,
    findCoreInfo,
    findHostInfoByType,
    phoneValid, picHeader,
    sendGetRequest, sendPostRequest, serviceEmailTemp, servicePhoneTemp, setHostPwd, setServicePwd, updateAward
} from "../util/Request";
import {connectionError, fail, failReturn, findMsgByError, successReturn} from "../util/ErrorMgr";
import {
    CorePojo,
    doLogout,
    getPicTypeByName,
    imageUploadCallBack,
    validNum,
    validPercent,
    validPwd
} from "../util/Common";
import {Input, message, Popover} from "antd";
import {CloseOutlined, EditOutlined, KeyOutlined} from "@ant-design/icons";

const selectedStyle = {background: "rgb(149,149,149)", color: "rgb(255,255,255)"}

const awardLine = (level, info) => {
    let max = level["awardLevel"] === "LEVEL_FOUR" ? "MAX" : level["maxValue"] + " CZH";
    return (
        <div className={"award-info-level"}>
            <span style={{fontWeight: "bold", width: "12%", textAlign: "center", color: "rgb(40,85,139)"}}>{info}</span>
            <span style={{fontWeight: "bold", width: "10%", textAlign: "center"}}>{"比例："}</span>
            <span style={{width: "10%", textAlign: "center"}}>{level["awardPercent"]}</span>
            <span style={{fontWeight: "bold", width: "10%", textAlign: "center"}}>{"范围："}</span>
            <span style={{width: "24%", textAlign: "center"}}>{level["minValue"] + " CZH"}</span>
            <span style={{fontWeight: "bold", width: "4%", textAlign: "center"}}>{"TO"}</span>
            <span style={{width: "24%", textAlign: "center"}}>{max}</span>
        </div>
    )
}

const paramTypes = {
    award: 1,
    base: {price: 7, min: 8},
    payLink: 3,
    payQr: 4,
    mgrPass: 5,
    payPass: 6,
    basePass: 9,
    infoPass: 10
}

const paramMsg = {
    3: {inputInfo: "请输入新支付链接", valid: "", success: "修改支付链接成功！"},
    4: {inputInfo: "", valid: validPercent, success: "修改付款二维码成功！"},
    7: {inputInfo: "请输入CZH单价", valid: validPercent, success: "CZH单价更新成功！"},
    8: {inputInfo: "请输入最小私募数量", valid: validNum, success: "最小私募数量更新成功！"},
    5: {inputInfo: "请输入新密码", valid: validPwd, success: "管理员密码更新成功！"},
    6: {inputInfo: "请输入新秘钥", valid: validPwd, success: "支付信息秘钥修改成功！"},
    9: {inputInfo: "请输入新秘钥", valid: validPwd, success: "基本信息/奖励规则秘钥修改成功！"},
    10: {inputInfo: "请输入新秘钥", valid: validPwd, success: "用户订单信息编辑秘钥修改成功！"},
}

class CoreSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            selectBase: true,
            coreLoaded: false,
            coreInfo: "",
            awardInfo: "",
            editType: 0,
            awardUpdateInfo: "",
            validEdit: false,
            keyType: 0,
            hostInfo: "",
            corePass: "",
            secCode: "",
            validType: 0,
            paramType: 0,
            coreUpdateInfo: "",
            isTrc: true,
            updateParam: "",
        }
    }

    setAuditInfo = (auditInfo) => {
        this.findCoreInfo(auditInfo).then(result => {
            let core = result[0];
            let award = result[1];
            if (core && award) {
                this.setState({
                    auditInfo: auditInfo,
                    coreInfo: core,
                    awardInfo: award,
                    coreLoaded: true,
                })
            }
        })
    }

    loadingCoreState = (item, coreInfo) => {
        let itemKey = item[CorePojo.itemKey];
        switch (itemKey) {
            case "CZH_MIN": {
                coreInfo.minAmount = item[CorePojo.itemValue];
                return;
            }
            case "CZH_PRICE": {
                coreInfo.exChangeRate = item[CorePojo.itemValue];
                return;
            }
            case "USDT_LINK": {
                coreInfo.payLink = this.convertLink(item[CorePojo.itemValue], item[CorePojo.itemSec]);
                return;
            }
            case "USDT_QR": {
                coreInfo.qrSrc = item[CorePojo.itemValue];
                coreInfo.qrSec = item[CorePojo.itemSec];
                return;
            }
            case "USDT_LINK_T": {
                coreInfo.payLinkT = this.convertLink(item[CorePojo.itemValue], item[CorePojo.itemSec]);
                return;
            }
            case "USDT_QR_T": {
                coreInfo.qrSrcT = item[CorePojo.itemValue];
                coreInfo.qrSecT = item[CorePojo.itemSec];
                return;
            }
            default: {
                return;
            }
        }
    }

    convertLink = (p1, p2) => {
        let lenTotal = p1.length + p2.length;
        let p1s = p1.split("");
        let p2s = p2.split("");
        let result = "";
        let id1 = 0;
        let id2 = 0;
        for (let i = 0; i < lenTotal; i++) {
            if (i % 2 === 0) {
                result += p1s[id1];
                id1++;
            } else {
                result += p2s[id2];
                id2++;
            }
        }
        return result;
    }

    findCoreInfo(auditInfo) {
        return Promise.all([this.handleFindCore(auditInfo), this.handleFindAward()]);
    }

    handleFindCore(auditInfo) {
        return sendGetRequest(findCoreInfo, {audit: auditInfo["userName"]}).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (result.success) {
                    let coreData = result[data];
                    if (coreData.length !== 6) {
                        console.log(coreData)
                        fail("参数缺失，请校验数据完整性！")
                        return "";
                    }
                    let coreInfo = {
                        minAmount: "",
                        exChangeRate: "",
                        payLink: "",
                        qrSrc: "",
                        qrSec: "",
                        payLinkT: "",
                        qrSrcT: "",
                        qrSecT: "",
                    }
                    coreData.forEach((item) => {
                        this.loadingCoreState(item, coreInfo);
                    })
                    return coreInfo;
                } else {
                    let errorL = result[error];
                    fail(findMsgByError(errorL));
                    return "";
                }
            }
        })
    }

    handleFindAward() {
        return sendGetRequest(findAwardInfo, {award: "award"}).then(awardRes => {
            if (awardRes === connectionError) {
                fail(findMsgByError(connectionError));
                return "";
            } else {
                if (awardRes.success) {
                    let dataL = awardRes[data];
                    if (dataL.length !== 4) {
                        fail("奖励信息错误，请校验数据！")
                        return;
                    }
                    let awards = new Map();
                    dataL.forEach((item) => {
                        awards.set(item["awardLevel"], item);
                    })
                    return awards;
                } else {
                    fail(findMsgByError(awardRes[error]))
                    return "";
                }
            }
        })
    }

    loadCurrencyBaseParamWin = (selectBase, coreInfo) => {
        let awardInfo = this.state.awardInfo;
        let editAward = this.state.editAward;
        let levelOne = awardInfo.get("LEVEL_ONE");
        let levelTwo = awardInfo.get("LEVEL_TWO");
        let levelThree = awardInfo.get("LEVEL_THREE");
        let levelFour = awardInfo.get("LEVEL_FOUR");
        let updateMap = new Map();
        return (
            selectBase ?
                <div className={"base-info-show"}>
                    <div className={"base-info-show-content"}>
                        <div className={"base-info-show-item"}>
                            <div className={"base-info-show-item-des"}>
                                <span style={{fontWeight: "bold"}}>{"CZH单价："}</span>
                                <span>{coreInfo.exChangeRate}</span>
                                <span>{" USDT"}</span>
                            </div>
                            <div className={"base-info-show-item-edit"}>
                                <button className={"core-edit-button"} onClick={() => {
                                    this.setState({
                                        editType: paramTypes.base.price,
                                        paramType: paramTypes.base.price,
                                        keyType: 3,
                                    })
                                }}>{"点击修改"}</button>
                            </div>
                        </div>
                        <div className={"base-info-show-item"}>
                            <div className={"base-info-show-item-des"}>
                                <span style={{fontWeight: "bold"}}>{"最小私募数量："}</span>
                                <span>{coreInfo.minAmount}</span>
                            </div>
                            <div className={"base-info-show-item-edit"}>
                                <button className={"core-edit-button"} onClick={() => {
                                    this.setState({
                                        editType: paramTypes.base.min,
                                        paramType: paramTypes.base.min,
                                        keyType: 3,
                                    })
                                }}>{"点击修改"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={"award-info-out"}>
                    <div className={"award-info-content"}>
                        {editAward ? this.awardInputLine(levelOne, "一阶奖励", updateMap) : awardLine(levelOne, "一阶奖励")}
                        {editAward ? this.awardInputLine(levelTwo, "二阶奖励", updateMap) : awardLine(levelTwo, "二阶奖励")}
                        {editAward ? this.awardInputLine(levelThree, "三阶奖励", updateMap) : awardLine(levelThree, "三阶奖励")}
                        {editAward ? this.awardInputLine(levelFour, "四阶奖励", updateMap) : awardLine(levelFour, "四阶奖励")}
                    </div>
                    <div className={"award-info-bottom"}>
                        {editAward ?
                            <div className={"award-info-input-bottom"}>
                                <button className={"award-info-btn"}
                                        style={{width: "70px", background: "rgb(199,24,24)"}}
                                        key={"cancel"} onClick={() => {
                                    this.editAwardRule(false)
                                }}>{"取消"}</button>
                                <button className={"award-info-btn"}
                                        style={{width: "70px", background: "rgb(21,191,44)"}}
                                        key={"commit"} onClick={() => {
                                    this.handleAwardUpdate(updateMap)
                                }}>{"确认"}</button>
                            </div>
                            :
                            <button className={"award-info-btn"} onClick={() => {
                                this.editAwardRule(true)
                            }}>{"编辑奖励规则"}</button>
                        }
                    </div>
                </div>
        )
    }

    handleAwardUpdate = (updateMap) => {
        if (updateMap.size === 0) {
            fail("输入参数与原参数相同！")
            return;
        }
        let isLegal = true;
        updateMap.forEach((item) => {
            console.log(item)
            let percent = item["awardPercent"] + "";
            let min = item["minValue"] + "";
            let max = item["maxValue"] + "";
            if (!percent || !min || !max || !validPercent.test(percent) || !validNum.test(min) || !validNum.test(max)
                || parseInt(max) < parseInt(min)) {
                isLegal = false;
            }
        })
        if (!isLegal) {
            fail("参数不合法，请校验！")
            return;
        }
        this.setState({
            awardUpdateInfo: updateMap,
            validEdit: true,
            keyType: 3,
            validType: 1,
            paramType: paramTypes.award
        })
    }

    setAwardInput = (e, levelKey, map, prop, level) => {
        let levelL;
        if (map.has(levelKey)) {
            levelL = map.get(levelKey);
        } else {
            levelL = level;
        }
        levelL[prop] = e.target.value;
        map.set(levelKey, levelL);
    }

    awardInputLine = (level, info, updateMap) => {
        let levelKey = level["awardLevel"];
        let max = levelKey === "LEVEL_FOUR" ? "MAX" : level["maxValue"];
        let levelL = Object.assign({}, level);
        return (
            <div className={"award-info-level"}>
                <span style={{
                    fontWeight: "bold",
                    width: "12%",
                    textAlign: "center",
                    color: "rgb(40,85,139)"
                }}>{info}</span>
                <span style={{fontWeight: "bold", width: "10%", textAlign: "center"}}>{"比例："}</span>
                <div style={{width: "10%", textAlign: "center"}} key={"rate"}>
                    <Input size="small"
                           placeholder="请输入比例"
                           defaultValue={level["awardPercent"]}
                           maxLength={6}
                           onChange={(e) => this.setAwardInput(e, levelKey, updateMap, "awardPercent", levelL)}
                    />
                </div>
                <span style={{fontWeight: "bold", width: "10%", textAlign: "center"}}>{"范围："}</span>
                <div style={{width: "24%", textAlign: "center"}} key={"min"}>
                    <Input size="small"
                           placeholder="请输入最小值"
                           defaultValue={level["minValue"]}
                           maxLength={12}
                           onChange={(e) => this.setAwardInput(e, levelKey, updateMap, "minValue", levelL)}
                    />
                </div>
                <span style={{fontWeight: "bold", width: "4%", textAlign: "center"}}>{"TO"}</span>
                <div style={{width: "24%", textAlign: "center"}} key={"max"}>
                    <Input size="small"
                           placeholder="请输入最大值"
                           defaultValue={max}
                           disabled={max === "MAX"}
                           maxLength={12}
                           onChange={(e) => this.setAwardInput(e, levelKey, updateMap, "maxValue", levelL)}
                    />
                </div>
            </div>
        )
    }

    editAwardRule = (value) => {
        this.setState({
            editAward: value,
            awardUpdateInfo: "",
            keyType: 0
        })
    }

    handleImgUpload = (e, tag, mHeight, mWidth) => {
        let file = e.target.files[0];
        let type = getPicTypeByName(file.name);
        if ("png" !== type) {
            fail("图片格式需要为png！")
            return;
        }

        imageUploadCallBack(file, mHeight, mWidth).then(res => {
            let param = res.data.link.split(",")[1];
            this.setState({
                updateParam: param,
                paramType: paramTypes.payQr,
                keyType: 2,
                validEdit: true,
                validType: 1,
            })
        })
    }

    loadPaymentInfoWin = (coreInfo, isTrc) => {
        return (
            <div className={"payment-info"}>
                <div className={"payment-info-content"}>
                    <div className={"qr-pay-layer"}>
                        <div className={"qr-pic-level"}>
                            <img className={"qr-pic-level-img"} src={isTrc ? picHeader + coreInfo.qrSrcT : picHeader + coreInfo.qrSrc}
                                 alt={""}/>
                        </div>
                        <div className={"qr-pic-level"}>
                            <label htmlFor="file" className={"qr-button-pic"}>{"编辑付款二维码"}</label>
                            <input type="file" name="file" id="file" className="input-file"
                                   accept={"image/png"}
                                   onChange={e => this.handleImgUpload(e, "pic", 400, 400)}
                            />
                        </div>
                    </div>
                    <div className={"link-pay-layer"}>
                        <div className={"pay-link-line"}>
                            <span style={{fontWeight: "bold"}}>{"支付链接："}</span>
                            <Popover
                                content={isTrc ? coreInfo.payLinkT : coreInfo.payLink}>
                                <div className={"check-line-info"}>{"显示链接"}</div>
                            </Popover>
                        </div>
                        <div className={"pay-link-btn"}>
                            <button className={"qr-button"} style={{width: "60px"}} onClick={() => {
                                this.setState({
                                    editType: paramTypes.payLink,
                                    paramType: paramTypes.payLink,
                                    keyType: 2,
                                })
                            }}>{"修改"}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    securePwd = (pwd) => {
        let len = pwd.length;
        if (len < 4) {
            return pwd;
        }
        let ps = pwd.split("");
        return ps[0] + "********" + ps[len - 1];
    }

    loadHostWin = () => {
        let auditInfo = this.state.auditInfo;
        return (
            <div className={"core-key-content-outer"}>
                <div className={"core-key-content"}>
                    <div className={"host-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"用户名："}</span>
                            <span>{auditInfo["userName"]}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}/>
                    </div>
                    <div className={"host-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"登录密码："}</span>
                            <span>{this.securePwd(auditInfo["pwd"])}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}>
                            <button className={"core-edit-button"} onClick={() => {
                                this.setState({
                                    editType: paramTypes.mgrPass,
                                    paramType: paramTypes.mgrPass,
                                    keyType: 4,
                                })
                            }}>{"修改密码"}</button>
                        </div>
                    </div>
                    <div className={"host-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"手机号码："}</span>
                            <span>
                                {/*{"(" + auditInfo["country"] + ") " + auditInfo["userPhone"]}*/}
                                {this.handlePhone(auditInfo["userPhone"])}
                            </span>
                        </div>
                        <div className={"core-key-content-item-btn"}/>
                    </div>
                    <div className={"host-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"邮箱地址："}</span>
                            <span>{this.handleEmail(auditInfo["userEmail"])}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}/>
                    </div>
                </div>
            </div>
        )
    }

    handleEmail = (email) => {
        let chars = email.split("")
        let indexT = chars.indexOf("@");
        if (indexT < 4) {
            return email;
        }
        let len = email.length;
        return email.substring(0, 3) + "******" + email.substring(indexT, len)
    }

    handlePhone = (phone) => {
        let len = phone.length;
        if (len < 7) {
            return phone;
        }
        return phone.substring(0, 3) + "******" + phone.substring(len - 3, len);
    }

    loadCoreKeyWin = () => {
        return (
            <div className={"core-key-content-outer"}>
                <div className={"core-key-content"}>
                    <div className={"core-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"USDT支付信息秘钥"}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}>
                            <button className={"core-edit-button"} onClick={() => {
                                this.setState({
                                    editType: paramTypes.payPass,
                                    paramType: paramTypes.payPass,
                                    keyType: 2,
                                })
                            }}>{"点击修改"}</button>
                        </div>
                    </div>
                    <div className={"core-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"CZH基本信息/奖励规则秘钥"}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}>
                            <button className={"core-edit-button"} onClick={() => {
                                this.setState({
                                    editType: paramTypes.basePass,
                                    paramType: paramTypes.basePass,
                                    keyType: 3,
                                })
                            }}>{"点击修改"}</button>
                        </div>
                    </div>
                    <div className={"core-key-content-item"}>
                        <div className={"core-key-content-item-des"}>
                            <span style={{fontWeight: "bold"}}>{"用户订单信息编辑秘钥"}</span>
                        </div>
                        <div className={"core-key-content-item-btn"}>
                            <button className={"core-edit-button"} onClick={() => {
                                this.setState({
                                    editType: paramTypes.infoPass,
                                    paramType: paramTypes.infoPass,
                                    keyType: 1,
                                })
                            }}>{"点击修改"}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadPageContent = () => {
        let selectBase = this.state.selectBase;
        let coreLoaded = this.state.coreLoaded;
        let coreInfo = this.state.coreInfo;
        let isTrc = this.state.isTrc;
        return (
            <div className={"common-page-content"}>
                {coreLoaded ? <div className={"core-layout"}>
                    <div className={"core-side-layout"}>
                        <div className={"core-left-top"}>
                            <div className={"core-part-title-top"}>
                                <div className={"core-base-menus"}>
                                    <button className={"core-menu-button"}
                                            style={selectBase ? selectedStyle : {}} onClick={() => {
                                        this.setState({selectBase: true})
                                    }}>{"CZH基本信息"}</button>
                                    <button className={"core-menu-button"}
                                            style={!selectBase ? selectedStyle : {}} onClick={() => {
                                        this.setState({selectBase: false})
                                    }}>{"CZH奖励规则"}</button>
                                </div>
                            </div>
                            {this.loadCurrencyBaseParamWin(selectBase, coreInfo)}
                        </div>
                        <div className={"core-left-down"}>
                            <div className={"core-part-title-down"}>
                                <div className={"core-pay-title"}>
                                    {"USDT 收款相关信息"}
                                </div>
                                <div className={"core-pay-title-section"}>
                                    <span className={"core-pay-title-section-item"}
                                          style={isTrc ? {textDecoration: "underline"} : {}} onClick={() => {
                                        this.setState({isTrc: true})
                                    }}>{" Trc20 "}</span>
                                    <span>{"|"}</span>
                                    <span className={"core-pay-title-section-item"}
                                          style={isTrc ? {} : {textDecoration: "underline"}} onClick={() => {
                                        this.setState({isTrc: false})
                                    }}>{" Erc20"}</span>
                                </div>
                            </div>
                            {this.loadPaymentInfoWin(coreInfo, isTrc)}
                        </div>
                    </div>
                    <div className={"core-side-layout"}>
                        <div className={"core-right-top"}>
                            <div className={"core-part-title-top"}>
                                {"管理员信息概览"}
                            </div>
                            {this.loadHostWin()}
                        </div>
                        <div className={"core-right-down"}>
                            <div className={"core-part-title-down"}>
                                {"秘钥信息管理"}
                            </div>
                            {this.loadCoreKeyWin()}
                        </div>
                    </div>
                </div> : <div className={"core-layout"}>{"数据准备中，请稍后..."}</div>}
            </div>
        )
    }

    loadMenu = () => {
        return (

            <MenuL/>
        )
    }

    setInputs = (e, props) => {
        if (props === "corePass") {
            this.setState({
                corePass: e.target.value,
            })
        } else if (props === "sec") {
            this.setState({
                secCode: e.target.value,
            })
        } else {
            this.setState({
                updateParam: e.target.value,
            })
        }
    }

    sendPhoneValid = (info, keyType) => {
        sendGetRequest(phoneValid, {
            phone: keyType === 4 ? info["userPhone"] : info["phone"],
            code: info["country"],
            tempId: servicePhoneTemp,
            time: Math.random()
        }).then(sendPhone => {
            if (sendPhone === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (sendPhone) {
                    this.setState({
                        hostInfo: keyType === 4 ? "" : info,
                        validType: 2,
                    })
                } else {
                    fail("手机验证码发送失败")
                }
            }
        })
    }

    getHostInfo = (keyType) => {
        let corePass = this.state.corePass;
        if (!corePass) {
            fail("请输入有效秘钥！")
            return;
        }
        if (keyType === 4) {
            let auditInfo = this.state.auditInfo;
            if (auditInfo["pwd"] !== corePass) {
                fail("原密码错误，请重新输入！");
                return;
            }
            this.sendPhoneValid(auditInfo, keyType);
        } else {
            sendGetRequest(findHostInfoByType, {pwd: corePass, host: keyType}).then(res => {
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res.success) {
                        let hostInfo = res[data];
                        this.sendPhoneValid(hostInfo, keyType)
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    validPhoneAndSendEmailValid = (keyType, secCode) => {
        if (!secCode) {
            fail("请输入有效手机验证码！")
            return;
        }
        let email = keyType === 4 ? this.state.auditInfo["userEmail"] : this.state.hostInfo["email"];
        sendGetRequest(coreValid, {secCode: secCode, type: "phCode", host: keyType, time: Math.random()}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res) {
                    sendGetRequest(emailValid, {
                        email: email,
                        tempId: serviceEmailTemp,
                        subject: "管理平台邮箱验证",
                        host: keyType,
                        time: Math.random()
                    }).then(emailRes => {
                        console.log("email: ", email)
                        console.log("keyType: ", keyType)
                        console.log("emailRes: ", emailRes)
                        if (emailRes === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (emailRes) {
                                this.setState({
                                    secCode: "",
                                    validType: 3,
                                })
                            } else {
                                fail("邮箱验证码发送失败！")
                            }
                        }
                    })
                } else {
                    fail("手机验证码输入错误，请重新输入！")
                }
            }
        })
    }

    genAwardPojo = (awardInfo) => {
        let pojo = [];
        awardInfo.forEach(item => {
            pojo.push(item);
        })
        return pojo;
    }

    updateAwardInfo = () => {
        let awardInfo = this.state.awardUpdateInfo;
        if (!awardInfo) {
            fail("奖励规则无更新！")
            return;
        }
        let awardPojo = this.genAwardPojo(awardInfo);
        sendPostRequest({url: updateAward, data: awardPojo}).then(result => {
            if (result === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (result.success) {
                    successReturn("奖励规则更新成功！", "/coreSetting");
                } else {
                    failReturn(findMsgByError(result[error]), "/coreSetting");
                }
            }
        })
    }

    updateMgrPass = (paramType) => {
        let auditName = this.state.auditInfo["userName"];
        let param = this.state.updateParam;
        sendGetRequest(setServicePwd, {pwd: param, audit: auditName}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    message.success({
                        content: paramMsg[paramType].success,
                        duration: 2,
                        style: {marginTop: "10vh"}
                    }).then(() => {
                        doLogout(auditName, param, false);
                    })
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    updateBase = (paramType) => {
        let updateParam = this.state.updateParam;
        let isTrc = this.state.isTrc;
        let pojo = ""
        if (paramType === paramTypes.base.min) {
            pojo = {
                itemKey: "CZH_MIN",
                itemValue: updateParam,
                itemSec: "",
                imgUrl: "",
            }
        } else if (paramType === paramTypes.base.price) {
            pojo = {
                itemKey: "CZH_PRICE",
                itemValue: updateParam,
                itemSec: "",
                imgUrl: "",
            }
        } else if (paramType === paramTypes.payQr) {
            let coreInfo = this.state.coreInfo;
            pojo = {
                itemKey: isTrc ? "USDT_QR_T" : "USDT_QR",
                itemValue: isTrc ? coreInfo.qrSrcT : coreInfo.qrSrc,
                itemSec: isTrc ? coreInfo.qrSecT : coreInfo.qrSec,
                imgUrl: updateParam,
            }
        } else {
            pojo = {
                itemKey: isTrc ? "USDT_LINK_T" : "USDT_LINK",
                itemValue: updateParam,
                itemSec: "",
                imgUrl: "",
            }
        }
        sendPostRequest({url: editCoreInfo, data: pojo}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    successReturn(paramMsg[paramType].success, "/coreSetting");
                } else {
                    failReturn(findMsgByError(res[error]), "/coreSetting");
                }
            }
        })
    }

    updateHostPass = (keyType, paramType) => {
        sendGetRequest(setHostPwd, {pwd: this.state.updateParam, host: keyType}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res.success) {
                    successReturn(paramMsg[paramType].success, "/coreSetting");
                } else {
                    failReturn(findMsgByError(res[error]), "/coreSetting");
                }
            }
        })
    }

    validEmailAndUpdate = (keyType, secCode) => {
        if (!secCode) {
            fail("请输入有效邮箱验证码！")
            return;
        }
        sendGetRequest(coreValid, {secCode: secCode, type: "emCode", host: keyType, time: Math.random()}).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res) {
                    let paramType = this.state.paramType;
                    if (paramType === paramTypes.award) {
                        this.updateAwardInfo();
                    } else if (paramType === paramTypes.base.min || paramType === paramTypes.base.price
                        || paramType === paramTypes.payQr || paramType === paramTypes.payLink) {
                        this.updateBase(paramType);
                    } else if (paramType === paramTypes.mgrPass) {
                        this.updateMgrPass(paramType);
                    } else if (paramType === paramTypes.payPass || paramType === paramTypes.infoPass
                        || paramType === paramTypes.basePass) {
                        this.updateHostPass(keyType, paramType);
                    }
                } else {
                    fail("邮箱验证码错误，请重新输入！")
                }
            }
        })
    }

    handleValidation = (keyType) => {
        let validType = this.state.validType;
        let secCode = this.state.secCode;
        let inputInfo;
        let inputProp;
        if (validType === 1) {
            inputInfo = "请输入操作秘钥";
            inputProp = "corePass";
        } else if (validType === 2) {
            inputInfo = "请输入手机验证码";
            inputProp = "sec";
        } else {
            inputInfo = "请输入邮箱验证码";
            inputProp = "sec";
        }
        return (
            <div className={"item-msg-win"} key={"valid"} style={{zIndex: "100"}}>
                <div className={"core-pwd-win-valid-win"}>
                    <div style={{height: "24%", width: "100%", borderRadius: "5px 5px 0 0"}}>
                        <CloseOutlined style={{float: "right", marginRight: "10px", marginTop: "8px", color: "white"}}
                                       onClick={() => {
                                           this.setState({
                                               validEdit: false,
                                               keyType: 0,
                                               validType: 0,
                                               secCode: "",
                                               paramType: 0,
                                               updateParam: ""
                                           })
                                       }}/>
                    </div>
                    <div className={"core-pwd-win-valid-win-content"} key={validType}>
                        <Input.Password size="middle" placeholder={inputInfo}
                                        prefix={<KeyOutlined style={{color: "grey"}}/>}
                                        style={{fontSize: "14px", width: "60%"}}
                                        maxLength={30}
                                        onChange={(e) => this.setInputs(e, inputProp)}
                        />
                        <button className={"core-valid-commit"} onClick={() => {
                            if (validType === 1) {
                                this.getHostInfo(keyType);
                            } else if (validType === 2) {
                                this.validPhoneAndSendEmailValid(keyType, secCode);
                            } else if (validType === 3) {
                                this.validEmailAndUpdate(keyType, secCode);
                            }
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )
    }

    handleEditParam = (keyType, editType) => {
        let updateParam = this.state.updateParam;
        if (!updateParam) {
            fail("请输入有效参数！")
            return;
        }
        let valid = paramMsg[editType].valid;
        if (valid && !valid.test(updateParam)) {
            fail("请输入有效参数！")
            return;
        }
        this.setState({
            validEdit: true,
            validType: 1,
        })
    }

    editParamWin = (keyType) => {
        let editType = this.state.editType;
        return (
            <div className={"item-msg-win"} key={"param-edit"}>
                <div className={"core-pwd-win-valid-win"}>
                    <div style={{height: "24%", width: "100%", borderRadius: "5px 5px 0 0"}}>
                        <CloseOutlined style={{float: "right", marginRight: "10px", marginTop: "8px", color: "white"}}
                                       onClick={() => {
                                           this.setState({
                                               editType: 0,
                                               updateParam: "",
                                               paramType: 0,
                                           })
                                       }}/>
                    </div>
                    <div className={"core-pwd-win-valid-win-content"} key={editType + ""}>
                        <Input.Password size="middle" placeholder={paramMsg[editType].inputInfo}
                               prefix={<EditOutlined style={{color: "grey"}}/>}
                               style={{fontSize: "14px", width: "60%"}}
                               maxLength={100}
                               onChange={(e) => this.setInputs(e, "param")}
                        />
                        <button className={"core-valid-commit"} onClick={() => {
                            this.handleEditParam(keyType, editType);
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let validEdit = this.state.validEdit;
        let keyType = this.state.keyType;
        let editType = this.state.editType;
        console.log(this.state.coreInfo)
        return (
            [validEdit ? this.handleValidation(keyType) : null,
                editType === 0 ? null : this.editParamWin(),
                <PageLayout menuL={this.loadMenu()}
                            contentL={this.loadPageContent()}
                            parent={this}/>
            ]
        )
    }
}

export default CoreSetting;
