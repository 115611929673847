import React from 'react';
import './../index.css';
import {CloseOutlined, KeyOutlined, ShoppingOutlined, TrophyOutlined} from "@ant-design/icons";
import {Input} from "antd";
import {validNum} from "../util/Common";
import {fail, successReturn} from "../util/ErrorMgr";
import {addSwitch} from "../util/Tool";

class CoreValidWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rAmount: "",
            rAward: "",
            showValidPwd: false,
            corePass: "",
            display: false,
            purchaseCZH: "",
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let display = this.props.display;
        if (prevProps.display !== display) {
            this.setState({
                display: display,
            })
        }
    }

    setInputs(e, props) {
        let value = e.target.value;

        switch (props) {
            case "rAmount": {
                this.setState({
                    rAmount: value,
                })
                return;
            }
            case "rAward": {
                this.setState({
                    rAward: value,
                })
                return;
            }
            case "corePass": {
                this.setState({
                    corePass: value,
                })
                return;
            }
            case "purchaseCZH": {
                this.setState({
                    purchaseCZH: value,
                })
                return;
            }
            default: {
                return;
            }
        }
    }

    handleEditUser = () => {
        let purchaseCZH = this.state.purchaseCZH;
        if (!purchaseCZH || !validNum.test(purchaseCZH)) {
            fail("请输入有效数量！");
            return;
        }
        this.setState({
            showValidPwd: true,
        })
    }

    handleEditOrder = () => {
        let rAmount = this.state.rAmount;
        // let rAward = this.state.rAward;
        let rAward = "0";
        if (!validNum.test(rAmount) || !validNum.test(rAward)) {
            fail("请输入有效数量！");
            return;
        }
        this.setState({
            showValidPwd: true,
        })
    }

    orderCoreWin = () => {
        return (
            <div className={"core-pwd-win"}>
                <div className={"core-pwd-win-title"}>
                    <CloseOutlined style={{marginRight: "8px"}} onClick={() => {
                        this.props.parent.closeCoreValid()
                    }}/>
                </div>
                <div className={"core-pwd-win-content"}>
                    <div className={"core-pwd-win-content-boundary"}>
                        <div className={"core-pwd-win-input"}>
                                <span
                                    style={{
                                        width: "40%",
                                        color: "rgb(102,102,102)",
                                        fontWeight: "bold"
                                    }}>{"购买数量："}</span>
                            <Input size="middle" placeholder="实际购买数量"
                                   prefix={<ShoppingOutlined style={{color: "grey"}}/>}
                                   style={{fontSize: "14px", width: "60%"}}
                                   maxLength={12}
                                   onChange={(e) => this.setInputs(e, "rAmount")}
                            />
                        </div>
                        {/*<div className={"core-pwd-win-input"}>*/}
                        {/*        <span*/}
                        {/*            style={{*/}
                        {/*                width: "40%",*/}
                        {/*                color: "rgb(102,102,102)",*/}
                        {/*                fontWeight: "bold"*/}
                        {/*            }}>{"奖励数量："}</span>*/}
                        {/*    <Input size="middle" placeholder="实际奖励数量"*/}
                        {/*           prefix={<TrophyOutlined style={{color: "grey"}}/>}*/}
                        {/*           style={{fontSize: "14px", width: "60%"}}*/}
                        {/*           maxLength={12}*/}
                        {/*           onChange={(e) => this.setInputs(e, "rAward")}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"core-pwd-win-button"}>
                    <button className={"core-win-commit"} onClick={() => {
                        this.handleEditOrder()
                    }}>确认
                    </button>
                </div>
            </div>
        )
    }

    userCoreWin = () => {
        let user = this.props.user;
        return (
            <div className={"core-pwd-win"}>
                <div className={"core-user-pwd-win-title"}>
                    <div style={{width: "90%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <span style={{width: "86%"}}>{user}</span>
                        <div style={{width: "14%"}}>
                            <CloseOutlined style={{float: "right"}} onClick={() => {
                                this.props.parent.closeCoreValid()
                            }}/>
                        </div>
                    </div>
                </div>
                <div className={"core-pwd-win-content"} style={{height: "56%"}}>
                    <div className={"core-pwd-win-content-boundary"} style={{justifyContent: "space-evenly"}}>
                                <span
                                    style={{
                                        width: "86%",
                                        color: "rgb(102,102,102)",
                                        fontWeight: "bold"
                                    }}>{"私募CZH数量："}</span>
                        <Input size="middle" placeholder="请输入更新后CZH总数"
                               prefix={<ShoppingOutlined style={{color: "grey"}}/>}
                               style={{fontSize: "14px", width: "86%"}}
                               maxLength={12}
                               onChange={(e) => this.setInputs(e, "purchaseCZH")}
                        />
                    </div>
                </div>
                <div className={"core-pwd-win-button"}>
                    <button className={"core-win-commit"} onClick={() => {
                        this.handleEditUser()
                    }}>确认
                    </button>
                </div>
            </div>
        )
    }

    genCoreWin = (page) => {
        if (page === "order") {
            return (
                this.orderCoreWin()
            )
        } else {
            return (
                this.userCoreWin()
            )
        }
    }

    genPwdValidWin = (page) => {
        return (
            <div className={"core-pwd-win-valid-win"}>
                <div style={{height: "24%", width: "100%", borderRadius: "5px 5px 0 0"}}>
                    <CloseOutlined style={{float: "right", marginRight: "10px", marginTop: "8px", color: "white"}}
                                   onClick={() => {
                                       this.setState({showValidPwd: false})
                                   }}/>
                </div>
                <div className={"core-pwd-win-valid-win-content"}>
                    <Input.Password size="middle" placeholder="请输入操作秘钥"
                                    prefix={<KeyOutlined style={{color: "grey"}}/>}
                                    style={{fontSize: "14px", width: "60%"}}
                                    maxLength={30}
                                    onChange={(e) => this.setInputs(e, "corePass")}
                    />
                    <button className={"core-valid-commit"} onClick={() => {
                        this.handleEditValues(page)
                    }}>{"确认"}</button>
                </div>
            </div>
        )
    }

    handleEditValues = (page) => {
        let dom = this.props.parent;
        let corePwd = this.state.corePass;
        if (!corePwd) {
            fail("请输入有效秘钥！")
            return;
        }

        if (page === "order") {
            let rAmount = this.state.rAmount;
            let rAward = this.state.rAward;
            dom.handleEdit(rAmount, rAward, corePwd, false).then(res => {
                console.log(res)
                if (res) {
                    this.setState({
                        showValidPwd: false,
                    })
                }
            });
        } else {
            let purchaseCZH = this.state.purchaseCZH;
            this.setState({
                showValidPwd: false,
            })
            dom.handleEdit(purchaseCZH, corePwd).then(res => {
                dom.setProcessing(false);
                console.log(res)
                if (res) {
                    addSwitch();
                    successReturn("修改成功！", "/userRecord")
                }
            });
        }
    }

    render() {
        let showValidPwd = this.state.showValidPwd
        let page = this.props.page;
        let display = this.state.display;
        return (
            [<div className={"item-msg-win"} style={{display: (display && !showValidPwd) ? "" : "none"}}
                  key={"core"}>
                {this.genCoreWin(page)}
            </div>,
                <div className={"item-msg-win"} style={{display: showValidPwd ? "" : "none"}}
                     key={"pwd"}>
                    {this.genPwdValidWin(page)}
                </div>,
            ]
        )
    }
}

export default CoreValidWin;