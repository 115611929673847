import React from 'react';
import './page.css';
import './../index.css';
import MobileHeader from "./MobileHeader";

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            auditInfo: "",
        }
    }

    loadMobileContent = () => {
        return (
            <div className={"mobile-menu-content"}>
                <div className={"mobile-menu-item"} onClick={() => {
                    window.location.href = "/orders"
                }}>{"待审核订单"}</div>
                <div className={"mobile-menu-item"} onClick={() => {
                    window.location.href = "/allHistory"
                }}>{"历史订单查询"}</div>
                <div className={"mobile-menu-item"} onClick={() => {
                    window.location.href = "/userRecord"
                }}>{"用户信息查询"}</div>
            </div>
        )
    }

    setAuditInfo = (auditInfo) => {
        this.setState({
            auditInfo: auditInfo
        })
    }

    setLogin = () => {
        this.setState(
            {
                isLogin: true,
            }
        )
    }

    render() {
        return (
            <div className={"mobile-menu"} style={{display: this.state.isLogin ? "" : "none"}}>
                <MobileHeader parent={this}/>
                {this.loadMobileContent()}
            </div>
        )
    }
}

export default MobileMenu;