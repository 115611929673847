import React, {Fragment} from 'react';
import './page.css';
import './../index.css';
import PageLayout from "./PageLayout";
import MenuL from "../item/MenuL";
import {Image, Input, Select} from "antd";
import {data, editMediaSources, error, findMediaSources, genPojo, sendPostRequest} from "../util/Request";
import {connectionError, fail, findMsgByError, success} from "../util/ErrorMgr";
import {getPicTypeByName, handleImgUpload, imageUploadCallBack} from "../util/Common";
import * as Console from "console";

const { Option } = Select;

class MediaMgr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auditInfo: "",
            language: this.props.match.params.lang,
            isMediaLoad: false,
            mediaPojo: [],
            imgUrl: "",
            showModal: false,
            modalContent: "",
            editContent: "",
        };
    }

    mediaInfo = {
        mediaBase: {
            langCode: "", // 语言标识
            itemType: "", // 数据种类--1为图片,2为文本
        }
    }

    setAuditInfo = (auditInfo) => {
        this.setItemType(1, this.state.language, this.mediaInfo.mediaBase);
        this.setState({
            auditInfo: auditInfo,
        })
    }

    loadTopBarWin = (lang, mediaBase) => {
        return (
            <Fragment>
                <Select className={"media-top-layout-select"} defaultValue={"1"} onChange={(e) => {this.setItemType(e, lang, mediaBase)}}>
                    <Option className={"media-top-layout-select-ele"}  value="1">
                        {"图片"}
                    </Option>
                    <Option className={"media-top-layout-select-ele"}   value="2">
                        {"文本"}
                    </Option>
                </Select>
            </Fragment>
        )
    }

    setItemType = (e, lang, mediaBase) => {
        mediaBase["langCode"] = lang;
        mediaBase["itemType"] = e;
        this.setState({
            mediaBase,
        },
            () => {
            sendPostRequest(genPojo(findMediaSources, mediaBase)).then(res => {
                console.log(res)
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res.success) {
                        this.setState({
                            mediaPojo: res[data],
                            isMediaLoad: true,
                        })
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })}
        )
    }

    loadMediaContentWin = (mediaPojo, auditInfo, showModal, page) => {
        let picHeader = "https://f892.cjz.vip";
        let mediaList = [];
        mediaPojo.forEach(function(item, index) {
            if (item.itemType === 1) {
                mediaList.push(
                    <div className={"media-center-layout-ele"} key={index}>
                        <div className={"media-center-layout-ele-title"}>
                            {item.locationPage + ' - ' + item.itemKey}
                        </div>
                        <div className={"media-center-layout-ele-img"}>
                            <Image className={"img-size"}
                                   width={'auto'} height={'auto'}
                                   src={picHeader + item.itemContent} alt={"pic"}
                            />
                        </div>
                        <div className={"media-center-layout-ele-fun"} key={index}>
                            <label htmlFor={"file" + index} className={"media-center-layout-ele-button"}>替换</label>
                            <input type="file" name="file" id={"file" + index} className="input-file"
                                   accept={item.itemContent.split(".")[1]}
                                   onChange={e => this.handleImgUploadMgr(e, "pic", 1400, 1400, item, index, auditInfo)}
                            />
                        </div>
                    </div>
                );
            } else {
                mediaList.push(
                    <div className={"media-center-layout-ele"} key={index}>
                        <div className={"media-center-layout-ele-title"}>
                            {item.locationPage + " - " + item.itemKey}
                        </div>
                        <div className={"media-center-layout-ele-content"}>
                            {page.handleItemContent(item.itemContent)}
                        </div>
                        <div className={"media-center-layout-ele-fun"}>
                            <button className={"media-center-layout-ele-button"}
                                    onClick={() =>{page.opModal(showModal, item)}}
                            >
                                {"编辑"}
                            </button>
                        </div>
                    </div>
                );
            }
        }, this)
        return mediaList;
    }

    handleImgUploadMgr = (e, tag, mHeight, mWidth, item, index, auditInfo) => {
        console.log(e)
        console.log(item)
        let file = e.target.files[0];

        if (file && item) {
            let fileTag = getPicTypeByName(file.name);
            let itemType = getPicTypeByName(item.itemContent);

            console.log(fileTag)
            console.log(itemType)

            if (fileTag !== itemType) {
                fail("图片格式不正确，请重新选择！");
            } else {
                imageUploadCallBack(file, mHeight, mWidth).then(res => {
                    console.log(res)
                    item["imgUrl"] = res.data.link.split(",")[1];
                    item["auditName"] = auditInfo.userName;
                    item["pwd"] = auditInfo.pwd;
                    console.log(item)
                    sendPostRequest(genPojo(editMediaSources, item)).then(result => {
                        console.log(result)
                        if (result.success) {
                            success("图片长传成功！")
                            window.location.reload();
                        } else {
                            fail(findMsgByError(result[error]))
                        }
                    })
                })
            }
        }
    }

    handleItemContent(content) {
        if (!content) {
            return "";
        }
        if (content.length <= 100) {
            return content;
        }
        return content.substring(0, 100) + "......";
    }

    opModal = (showModal, item) => {
        console.log("showModal", showModal)
        console.log("item", item)
        this.setState({
            showModal: !showModal,
            modalContent: item,
            editContent: item && item.itemContent,
        });
    }

    loadingModal = (showModal, modalContent, auditInfo, editContent) => {
        let lang = this.state.language;
        console.log(lang)
        if (showModal) {
            return (
                <div className={"item-msg-win"}>
                    <div className={"modal-win"}>
                        <div className={"modal-win-title"}>
                            <div className={"modal-win-title-text"}>
                                {modalContent.locationPage + " - " + modalContent.itemKey + "  文本编辑"}
                            </div>
                            <button className={"modal-win-close"}
                                    onClick={() => {
                                        this.opModal(showModal, "")
                                    }}
                            >
                                ×
                            </button>
                        </div>
                        <div className={"modal-win-textarea-container"}>
                            <textarea
                                className={"modal-win-textarea"}
                                name="txt"
                                maxLength={lang === "CN" ? 1000 : 3000}
                                onChange={e => {this.handleContentInput(e)}}
                                defaultValue={editContent}
                            />
                        </div>
                        <div className={"modal-win-bottom"}>
                            <button className={"modal-win-button"}
                                    onClick={() => {this.commitEdit(showModal, modalContent, auditInfo, editContent, lang)}}
                            >
                                {"确认"}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 字符串转字节序列
    stringToByte = (str) => {
        let bytes = [];
        let len, c;
        len = str.length;
        for(let i = 0; i < len; i++) {
            c = str.charCodeAt(i);
            if(c >= 0x010000 && c <= 0x10FFFF) {
                bytes.push(((c >> 18) & 0x07) | 0xF0);
                bytes.push(((c >> 12) & 0x3F) | 0x80);
                bytes.push(((c >> 6) & 0x3F) | 0x80);
                bytes.push((c & 0x3F) | 0x80);
            } else if(c >= 0x000800 && c <= 0x00FFFF) {
                bytes.push(((c >> 12) & 0x0F) | 0xE0);
                bytes.push(((c >> 6) & 0x3F) | 0x80);
                bytes.push((c & 0x3F) | 0x80);
            } else if(c >= 0x000080 && c <= 0x0007FF) {
                bytes.push(((c >> 6) & 0x1F) | 0xC0);
                bytes.push((c & 0x3F) | 0x80);
            } else {
                bytes.push(c & 0xFF);
            }
        }
        return bytes;
    }

    handleContentInput = (e) => {
        this.setState({
            editContent: e.target.value,
        })
    }

    commitEdit = (showModal, modalContent, auditInfo, editContent, lang) => {
        let cLength = this.stringToByte(editContent).length;
        console.log("字符长度", cLength)
        if (cLength <= 3000) {
            modalContent["itemContent"] = editContent;
            modalContent["auditName"] = auditInfo.userName;
            modalContent["pwd"] = auditInfo.pwd;
            console.log("修改文字：", modalContent)
            sendPostRequest(genPojo(editMediaSources, modalContent)).then(result => {
                console.log(result)
                if (result.success) {
                    this.opModal(showModal, "");
                } else {
                    fail(findMsgByError(result[error]))
                }
            })
        } else {
            fail("文本编辑内容过长，字数限制为中文1000个字，英文3000个字母，请重新编辑！");
            this.opModal(showModal);
        }
    }

    loadPageContent = (isMediaLoad, lang, mediaBase, mediaPojo, auditInfo, showModal) => {
        return (
            isMediaLoad ?
            <div className={"common-page-content"}>
                <div className={"media-layout"}>
                    <div className={"media-top-layout"}>
                        {this.loadTopBarWin(lang, mediaBase)}
                    </div>
                    <div className={"media-center-layout"}>
                        {this.loadMediaContentWin(mediaPojo, auditInfo, showModal, this)}
                    </div>
                    <div className={"media-button-layout"}/>
                </div>
            </div>
                :
            <div className={"common-page-content"}>
                {"页面静态资源加载中,请稍后..."}
            </div>
        )
    }

    loadMenu = () => {
        return (
            <MenuL/>
        )
    }

    render() {
        let lang = this.state.language;

        let auditInfo = this.state.auditInfo;
        let isMediaLoad = this.state.isMediaLoad;
        let mediaBase = this.mediaInfo.mediaBase;
        let mediaPojo = this.state.mediaPojo;

        let showModal = this.state.showModal;
        let modalContent = this.state.modalContent;
        let editContent = this.state.editContent;

        console.log(this.state.auditInfo)
        console.log(this.state.mediaPojo)

        return (
            [showModal && this.loadingModal(showModal, modalContent, auditInfo, editContent),
            <PageLayout menuL={this.loadMenu()}
                        contentL={this.loadPageContent(isMediaLoad, lang, mediaBase, mediaPojo, auditInfo, showModal)}
                        parent={this}/>]
        )
    }
}

export default MediaMgr;
