import {error, logout, sendGetRequest} from "./Request";
import React from "react";
import {FileOutlined} from "@ant-design/icons";
import {Input} from "antd";
import {
    connectionError,
    fail,
    failMobile,
    failMobileReturn,
    failReturn,
    findMsgByError,
    successMobileReturn,
    successReturn
} from "./ErrorMgr";

const OrderPojo = {
    userName: "userName",
    pTime: "pTime",
    orderNo: "orderNo",
    pAmount: "pAmount",
    usdtPrice: "usdtPrice",
    inviterName: "inviterName",
    rAmount: "rAmount",
    cTime: "cTime",
    inviterAward: "inviterAward",
    rAward: "rAward",
    orderStatus: "orderStatus",
    rejectReason: "rejectReason",
}

const CorePojo = {
    itemKey: "itemKey",
    itemValue: "itemValue",
    itemSec: "itemSec",
    imgUrl: "imgUrl",
}

const UserPojo = {
    userName: "userName",
    regTime: "regTime",
    userPhone: "userPhone",
    country: "country",
    userEmail: "userEmail",
    purchaseCZH: "purchaseCZH",
    awardCZH: "awardCZH",
    inviterName: "inviterName",
    inviterAward: "inviterAward",
    lastLogin: "lastLogin",
    lastPurchase: "lastPurchase",
    uId: "uId",
    initInfo: "initInfo",
}

const orderType = {
    all: 999,
    accept: 1,
    reject: 2,
}

const doLogout = (auditName, pwd, isMobile) => {
    let loginUrl = "/";
    sendGetRequest(logout, {
        pwd: pwd,
        userName: auditName,
        isTemp: false,
    }).then(result => {
        if (result === connectionError) {
            let cError = findMsgByError(connectionError);
            isMobile ? failMobileReturn(cError, loginUrl) : failReturn(cError, loginUrl);
        } else {
            if (result.success) {
                sessionStorage.removeItem("auditInfo")
                let sMsg = "退出登录成功！";
                isMobile ? successMobileReturn(sMsg, loginUrl) : successReturn(sMsg, loginUrl)
            } else {
                let fMsg = findMsgByError(result[error]);
                isMobile ? failMobile(fMsg) : fail(fMsg)
            }
        }
    })
}

const isMobile = () => {
    let ua = navigator.userAgent;
    let ipad = (ua.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)),
        isIphone = ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isTablet = ua.match(/(Tablet)\s+/),
        isMobile = ipad || isIphone || isAndroid || isTablet;

    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;

    let isTabWin = width < 1100 || height > width * 4;

    return !!isMobile || isTabWin;
}

const handleBatchInit = (dom, breakpoints, totalPages, dataLoad, pageLimits) => {
    let breakPointsMap = dom.state.breakpointsMap;
    breakpoints.forEach((item) => {
        breakPointsMap.set(item, false);
    })
    dom.setState({
        data: dataLoad,
        totalPages: totalPages,
        breakpoints: breakpoints,
        breakPointsMap: breakPointsMap,
        batchProcess: false,
    })
    loadDataToMap(dataLoad, 1, pageLimits, dom)
}

const batchProcessWin = (isMobile) => {
    let msg = "订单加载中，请稍后...";
    return (
        isMobile ?
            <div className={"mobile-batch-process-win"}>
                {msg}
            </div>
            :
            <div className={"batch-process-win"}>
                {msg}
            </div>
    )
}

const noDataWin = (msg, isMobile) => {
    return (
        isMobile ?
            <div className={"mobile-empty-data"}>
                <span>
                    {msg}
                </span>
            </div>
            : <div className={"no-data-win"}>
                <div className={"empty-data-content"}>
                    <FileOutlined style={{marginRight: "8px"}}/>
                    {msg}
                </div>
            </div>
    )
}

const loadingPageDataTable = (curPage, curDataMap, dom, isMobile, msg) => {
    let lines = [];
    if (curDataMap.has(curPage)) {
        let pageDataList = curDataMap.get(curPage);
        pageDataList.forEach((item, index) => {
            let line = isMobile ? dom.loadMbSingleData(item, index) : dom.loadSingleData(item, index)
            lines.push(line);
        })
    }
    if (lines.length === 0) {
        return noDataWin(msg, isMobile);
    }
    return lines;
}

const loadDataToMap = (data, pageNum, pL, dom) => {
    if (data.length !== 0) {
        let curDataMap = dom.state.pageDataMap;
        let count = 1;
        let pageL = pageNum;
        let pageData = []
        let last = data.length - 1 + "";
        for (let i in data) {
            if (data.hasOwnProperty(i)) {
                if (count < pL) {
                    pageData.push(data[i]);
                    if (i === last) {
                        curDataMap.set(pageL, pageData);
                        break;
                    }
                    count++;
                } else {
                    pageData.push(data[i]);
                    curDataMap.set(pageL, pageData);
                    pageL++;
                    count = 1;
                    pageData = [];
                }
            }
        }
        console.log("map")
        console.log(curDataMap)
        dom.setState({
            pageDataMap: curDataMap,
            batchProcess: false,
            data: [],
        })
    }
}

const switchPage = (targetPage, status, isSelect, curDataMap, dom) => {
    if (isSelect || !dom.loadBatch(targetPage, status, curDataMap)) {
        dom.setState({
            curPage: targetPage,
        })
    }
}

const loadingPartitionLayout = (curPage, totalPages, status, curDataMap, dom) => {
    return (
        <div className={"partition-layout-content"}>
            <div className={"switch-page"}>
                <button className={"switch-page-button"}
                        style={(curPage === 1 || totalPages === 0) ? {
                            background: "rgb(137,137,137)",
                            cursor: "not-allowed"
                        } : {}}
                        disabled={curPage === 1 || totalPages === 0}
                        onClick={() => {
                            switchPage(curPage - 1, status, false, curDataMap, dom)
                        }}>{"上一页"}</button>
                <div className={"current-page"}>{"当前页数:" + curPage + " | 总页数:" + totalPages}</div>
                <button className={"switch-page-button"}
                        style={(totalPages === 0 || curPage === totalPages) ? {
                            background: "rgb(137,137,137)",
                            cursor: "not-allowed"
                        } : {}}
                        disabled={totalPages === 0 || curPage === totalPages}
                        onClick={() => {
                            switchPage(curPage + 1, status, false, curDataMap, dom)
                        }}>{"下一页"}</button>
            </div>
            <div className={"input-page"}>
                <div style={{width: "48%", textAlign: "center"}}>
                    <Input size="small" placeholder="页码"
                           style={{fontSize: "14px", width: "46px"}}
                           maxLength={5}
                           onChange={(e) => dom.setSelectPage(e)}
                    />
                </div>
                <div style={{width: "48%", textAlign: "center"}}>
                    <button className={"to-input-page"}
                            style={(totalPages === 0) ? {
                                background: "rgb(137,137,137)",
                                cursor: "not-allowed"
                            } : {}}
                            disabled={totalPages === 0}
                            onClick={() => {
                                dom.handleToSelectPage(totalPages, status, curDataMap)
                            }}
                    >
                        {"跳转"}</button>
                </div>
            </div>
        </div>
    )
}

const rejectReasons = {
    UNPAID: {key: "UNPAID", content: " 未支付款项，请联系客服"},
    PAYMENT_MISMATCH: {key: "PAYMENT_MISMATCH", content: "支付款项数额不正确，请联系客服"},
    UNREASONED: {key: "UNREASONED", content: "驳回原因请联系客服"}
}

const validNum = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;

const validPercent = /^[0-9]\d*(\.\d+)?$/;

const validPwd = /^[^\s]{6,}$/;

/* 图片上传 */
const getPicTypeByName = (name) => {
    return name.split(".")[1];
}

const imageUploadCallBack = (file, maxHeight, maxWidth) => new Promise(
    (resolve) => {
        console.log(file)
        let names = file.name.split(".");
        // let type = file.type.split("/")[1];
        let type = names.length > 1 ? names[1] : "jpeg";
        if (type === "jpg") {
            type = "jpeg";
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let img = new Image();
        reader.onload = function (e) {
            img.src = this.result.toString()
        }
        console.log(img)
        img.onload = function () {
            // let canvas = document.createElement('canvas');
            // let context = canvas.getContext('2d');
            //
            // // 图片原始尺寸
            // let originWidth = this.width;
            // let originHeight = this.height;
            // // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
            // // 目标尺寸
            // let targetWidth = originWidth,
            //     targetHeight = originHeight;
            // // 图片尺寸超过尺寸的限制
            // if (originWidth > maxWidth || originHeight > maxHeight) {
            //     if (originWidth / originHeight > maxWidth / maxHeight) {
            //         // 更宽，按照宽度限定尺寸
            //         targetWidth = maxWidth;
            //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            //     } else {
            //         targetHeight = maxHeight;
            //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            //     }
            // }
            // // canvas对图片进行缩放
            // canvas.width = targetWidth;
            // canvas.height = targetHeight;
            // // 清除画布
            // context.clearRect(0, 0, targetWidth, targetHeight);
            // // 图片压缩
            // context.drawImage(img, 0, 0, targetWidth, targetHeight);

            //压缩后的图片转base64 url
            /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
              * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/

            let newUrl = "";
            if (type === "svg") {
                newUrl = "data:image/svg+xml;" + img.src.split(";")[1]
            } else {
                newUrl = "image/" + type + ";" + img.src.split(";")[1]
                // newUrl = canvas.toDataURL(
                //     'image/' + type, 0.92
                // );//base64 格式
            }

            resolve({
                data: {
                    link: newUrl,
                    type: type,
                }
            })

            console.log(newUrl)
            console.log(type)
        }
    }
)

export {
    OrderPojo,
    validNum,
    rejectReasons,
    orderType,
    handleBatchInit,
    loadDataToMap,
    batchProcessWin,
    noDataWin,
    loadingPageDataTable,
    UserPojo,
    switchPage,
    loadingPartitionLayout,
    isMobile,
    doLogout,
    CorePojo,
    validPercent,
    getPicTypeByName,
    imageUploadCallBack,
    validPwd
}
