import React from 'react';
import './../index.css'
import {Dropdown, Menu} from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import {CaretDownOutlined, CopyOutlined, DesktopOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {addSwitch} from "../util/Tool";

const menuOrder = (
    <Menu>
        <MenuItem className={"sub-menu-item"} key={"orders"} onClick={() => {
            addSwitch();
            window.location.href = "/orders";
        }}>
            <div>待审核订单</div>
        </MenuItem>
        <MenuItem className={"sub-menu-item"} key={"ordersHistory"} onClick={() => {
            addSwitch();
            window.location.href = "/allHistory"
        }}>
            <div>历史订单</div>
        </MenuItem>
    </Menu>
)

const menuMedia = (
    <Menu>
        <MenuItem className={"sub-menu-item"} key={"chinese"} onClick={() => {
            window.location.href = "/mediaMgr/CN"
        }}>
            <div>中文资源</div>
        </MenuItem>
        <MenuItem className={"sub-menu-item"} key={"english"} onClick={() => {
            window.location.href = "/mediaMgr/EN"
        }}>
            <div>英文资源</div>
        </MenuItem>
    </Menu>
)

class MenuL extends React.Component {
    render() {
        return (
            <div className={"menu-outer"}>
                <div className={"menu-layout"}>
                    <Dropdown overlay={menuOrder} placement="bottomCenter" arrow>
                        <div className={"menu-button"}>
                            <span><CopyOutlined/></span>
                            <span style={{width: "26%", marginLeft: "8px"}}>{"订单管理"}</span>
                            <span><CaretDownOutlined/></span>
                        </div>
                    </Dropdown>
                    <button className={"menu-button"} onClick={() => {
                        window.location.href = "/userRecord"
                    }}>
                        <span><UserOutlined/></span>
                        <span style={{marginLeft: "8px"}}>{"用户管理"}</span>
                    </button>
                    <Dropdown overlay={menuMedia} placement="bottomCenter" arrow>
                        <div className={"menu-button"}>
                            <span><DesktopOutlined/></span>
                            <span style={{width: "38%", marginLeft: "8px"}}>{"页面资源管理"}</span>
                            <span><CaretDownOutlined/></span>
                        </div>
                    </Dropdown>
                    <button className={"menu-button"} onClick={() => {
                        window.location.href = "/coreSetting"
                    }}>
                        <span><SettingOutlined/></span>
                        <span style={{marginLeft: "8px"}}>{"通用设置"}</span>
                    </button>
                </div>
            </div>
        )
    }
}

export default MenuL;